import React from 'react';

const AccountProfilesCards = ({ profiles, handleCardNav, checkType, checkPending, opacity = 1, height = "auto", width = "100%" }) => {
 // Sort profiles to display active ones first (not disabled, not pending)
 const sortedProfiles = profiles?.sort((a, b) => {
  const disabledCheckA = checkType(a.type);
  const pendingStateA = checkPending(a.status);
  const disabledCheckB = checkType(b.type);
  const pendingStateB = checkPending(b.status);

  // Place active profiles (not disabled and not pending) at the top
  if (disabledCheckA && !pendingStateA && !(disabledCheckB && !pendingStateB)) return -1;
  if (!(disabledCheckA && !pendingStateA) && disabledCheckB && !pendingStateB) return 1;
  return 0; // Keep original order if both profiles are either active or inactive
 });

 return (
  <div>
   <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 mt-4" style={{ opacity, height, width }}>
    {sortedProfiles?.map((profile) => {
     const disabledCheck = checkType(profile.type);
     const pendingState = checkPending(profile.status);

     return (
      <span
       key={profile.entity_id}
       onClick={() =>
        handleCardNav(disabledCheck && !pendingState, `/${profile.type.split('_')[0]}/${profile?.entity_id}/${profile?.member_type_id}`)
       }
       rel="noopener noreferrer"
      >
       <div
        className={`flex flex-col justify-between hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105 mb-4 ${disabledCheck && !pendingState ? "bg-white" : "bg-gray-50"} `}
       >
        <div className="left">
         <div className={`header ${disabledCheck && !pendingState ? "text-green-600" : "text-gray-800"} font-semibold text-xl mb-3`}>
          {profile.type.replace(/_/g, ' ').toUpperCase()}
         </div>
         <div className="text-sm font-medium text-gray-700 dark:text-gray-300">
          Organization Name:{" "}
          <span className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
           {profile.name.toUpperCase() || ""}
          </span>
         </div>
         <div className="text-sm font-medium text-gray-700 dark:text-gray-300 my-2">
          Status:{" "}
          <span
           className={`${disabledCheck && !pendingState
            ? "text-green-800"
            : "bg-red-200 text-red-800 text-sm font-semibold  mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
            }`}
          >
           {profile?.status.toUpperCase()}
          </span>
         </div>
         {profile.entity_mid && (
          <div className="text-sm font-medium text-gray-700 dark:text-gray-300 my-2">
           Membership Id:{" "}
           <span
            className={`${disabledCheck && !pendingState
             ? "text-green-800"
             : "bg-red-200 text-red-800 text-sm font-semibold  mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
             }`}
           >
            {profile?.entity_mid}
           </span>
          </div>
         )}
        </div>
       </div>
      </span>
     );
    })}
   </div>
  </div>
 );
};

export default AccountProfilesCards;
