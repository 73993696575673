import React, { useEffect, useState } from 'react';
import Icon from '../components/Icon';

function WelcomeBanner ({ bannerTitle = "Exchange Dashboard", view, data }) {
  const account = JSON.parse(localStorage.getItem('account'));
  const firstName = account.user.first_name;
  const lastName = account.user.last_name;

  const [banner_Title, setBannerTitle] = useState(bannerTitle);
  const [viewData, setViewData] = useState(data);

  useEffect(() => {
    setBannerTitle(bannerTitle);
  }, [bannerTitle]);

  useEffect(() => {
    setViewData(data);
  }, [data]);

  return (
    <div className="relative bg-gray-200 dark:bg-gray-800 p-6 sm:p-8 rounded-lg overflow-hidden shadow-sm mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
        style={{
          opacity: 0.2,
          height: '100%',
          maxWidth: '100%',
        }}
      >
        <div style={{ height: '100%' }}>
          <Icon style={{ height: '100%', maxWidth: '100%' }} />
        </div>
      </div>

      {/* Content */}
      <div className="relative">
        <h2 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-semibold mb-2">
          {
            view
              ? `Viewing ${viewData?.entity_name || ""} Dashboard`
              : `Welcome, ${firstName} ${lastName}`
          }
        </h2>
        <p className="text-sm md:text-base dark:text-gray-300">
          ZAMACE Integrated Digital Platform | <b>{banner_Title}</b>
        </p>
      </div>
    </div>
  );
}

export default WelcomeBanner;
