import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import RSelect from 'react-select';
import { amendWarehouseReceiptReset, amend_warehouse_receipt_absolute_owner, amend_warehouse_receipt_current_owner, amend_warehouse_receipt_negotiable_status, amend_warehouse_receipt_status, getEntityActiveStatusVerifiedList, getSpecificEntityProfileListReset, listAllProducersWithEntitiesReset, list_all_producers_with_entities, process_warehouse_receipt_outbound_shipment } from '../../utils/actions';
import * as app_consts from '../../utils/constants';
import { rSelectMapFun, retreive_rSelectVariables } from '../../utils/functionalUtils';
import { useNavigate, useParams } from 'react-router-dom';
import FileInput from '../../layouts/FileInput';

const WarehouseReceiptActionsModal = ({ isOpen, closeFunction, action, warehouseReceiptData, successFunc }) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const { entity_id, receipt_number } = useParams();

    const [module_data, setModuleData] = useState(warehouseReceiptData);
    const [page_action, setPageAction] = useState(action);
    const [entity_list, setEntityList] = useState([]);
    const [depositor_type, setDepositorType] = useState("");
    const [selectedDepositorType, setSelectedDepositorType] = useState([]);
    const [depositor_own_label, setDepositorOwnlabel] = useState("");
    const [producerList, setProducerList] = useState([]);
    const [producerLoading, setProducerLoading] = useState(true);
    const [errors, setErrors] = useState({});

    // console.log("selectedDepositorType: ", selectedDepositorType);

    useEffect(() => {
        setPageAction(action);
        if (isOpen)
        {
            dispatch(getEntityActiveStatusVerifiedList({ active: 1, status: "active", verified: 1 }));
            dispatch(list_all_producers_with_entities());
        }
    }, [action, isOpen]);

    // Listen to Entity List Data
    const entity_list_state = useSelector((state) => state.specificEntityProfileList);
    // 
    useEffect(() => {
        if (entity_list_state.status !== app_consts.IDLE)
        {
            if (entity_list_state.isLoading)
            {
                setProducerLoading(true);
            }
            else
            {
                setProducerLoading(false);
                const fetchData = async () => {
                    if (entity_list_state.data)
                    {
                        let mappedData = [];

                        await Promise.all(
                            entity_list_state?.data?.data?.map(async (data_to_map) => {
                                const { label, value } = retreive_rSelectVariables(app_consts.ENTITY_LIST_MAP, data_to_map);
                                const result = await rSelectMapFun(label, value);
                                mappedData.push(result);
                            })
                        );
                        setEntityList(mappedData);
                    }
                };

                fetchData();
                dispatch(getSpecificEntityProfileListReset());
            }
        }
    }, [entity_list_state]);

    // Listen to Module Data
    useEffect(() => {
        setModuleData(warehouseReceiptData);
    }, [warehouseReceiptData]);

    useEffect(() => {
        if (isOpen === true)
        {
            setLoading(false);
            setError("");
            formik.resetForm();
        }
    }, [isOpen]);

    const formik = useFormik({
        initialValues: {
            warehouse_status: "",
            negotiable_status: "",
            absolute_owner_id: "",
            current_owner_id: "",
            reason: "",
            gin_no: "",
            truck_load_no: "",
            no_of_bags: "",
            new_quantity: "",
            date_dispatched: "",
            support_document: "",
            dispatched_to: ""
        },
        onSubmit: async (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (formData) => {
        setError("");

        try
        {
            if (page_action === "Update Warehouse Status")
            {
                await dispatch(amend_warehouse_receipt_status({ receipt_number: module_data?.receipt_number, status: formData.warehouse_status }));
            } else if (page_action === "Update Negotiable Status")
            {
                await dispatch(amend_warehouse_receipt_negotiable_status({ receipt_number: module_data?.receipt_number, negotiable: formData.negotiable_status === "true" ? true : false, reason: formData.reason }));
            } else if (page_action === "Change Absolute Owner")
            {
                await dispatch(amend_warehouse_receipt_absolute_owner({ receipt_number: module_data?.receipt_number, absolute_owner_id: formData.absolute_owner_id, reason: formData.reason, depositor_type: depositor_type }));
            } else if (page_action === "Change Current Owner")
            {
                await dispatch(amend_warehouse_receipt_current_owner({ receipt_number: module_data?.receipt_number, current_owner_id: formData.current_owner_id, reason: formData.reason }));
            }
            else if (page_action === "Process Outbound")
            {
                // 
                await dispatch(process_warehouse_receipt_outbound_shipment({ receipt_number: module_data?.receipt_number, truck_load_no: formData.truck_load_no, no_of_bags: formData.no_of_bags, new_quantity: formData.new_quantity, date_dispatched: formData.date_dispatched, support_document: formData.support_document, dispatched_to: formData.dispatched_to, gin_no: formData.gin_no }));
            }
        } catch (error)
        {
            console.log(error);
        }
    };

    const handleRSelectChange = (option, fieldName) => {
        if (option)
        {
            formik.setFieldValue(fieldName, option.value);
        } else
        {
            formik.setFieldValue(fieldName, "");
        }
    };

    // Listen to Producers State
    const producersState = useSelector((state) => state.listAllProducersWithEntities);
    // 
    useEffect(() => {
        if (producersState.status !== app_consts.IDLE)
        {
            if (producersState.isLoading)
            {
                setProducerLoading(true);
            }
            else
            {
                setProducerLoading(false);
                if (producersState.error)
                {
                    // setError(producersState.message);
                }
                else if (producersState.data)
                {
                    setProducerList(producersState?.data);
                }
                dispatch(listAllProducersWithEntitiesReset());
            }
        }
    }, [producersState]);

    // Update Warehouse Receipt Event Listener

    // Listen to Update Warehuose State
    const amendWarehouseReceipt = useSelector((state) => state.amendWarehouseReceipt);
    // 
    useEffect(() => {
        if (amendWarehouseReceipt.status !== app_consts.IDLE)
        {
            if (amendWarehouseReceipt.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (amendWarehouseReceipt.error)
                {
                    setError(amendWarehouseReceipt.message);
                }
                else
                {
                    // Success Function
                    successFunc();
                }
                dispatch(amendWarehouseReceiptReset());
            }
        }
    }, [amendWarehouseReceipt]);

    // Handle Changes in Depositor Type Selection
    const handleDepositorTypeChange = async (depositorType) => {
        if (depositorType)
        {
            setDepositorType(depositorType);
            setSelectedDepositorType(await filterDepositors(depositorType));
        }
        else
        {
            setSelectedDepositorType([]);
        }
        if (depositorType !== app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            formik.setFieldValue('current_owner_id', "");
        }
    };

    const handleAbsoluteDepositorTypeChange = async (depositorType) => {
        if (depositorType)
        {
            setDepositorType(depositorType);
            setSelectedDepositorType(await filterAbsoluteDepositors(depositorType));
        }
        else
        {
            setSelectedDepositorType([]);
        }
        if (depositorType !== app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            formik.setFieldValue('absolute_owner_id', "");
        }
    };

    const filterAbsoluteDepositors = async (depositorType) => {
        let filteredList;


        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            filteredList = producerList?.filter((producer) => depositorType === producer.type && producer.entity_id === entity_id);
        }
        else
        {
            filteredList = producerList?.filter((producer) => depositorType === producer.type);
        }

        let mappedData = [];

        await Promise.all(
            filteredList?.map(async (data_to_map) => {
                const { label, value } = retreive_rSelectVariables(app_consts.ENTITY_PRODUCERS_LIST_MAP, data_to_map);
                const result = await rSelectMapFun(label, value);
                mappedData.push(result);
            })
        );
        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase() && mappedData)
        {
            setDepositorOwnlabel(mappedData[0]?.label);
            formik.setFieldValue('current_owner_id', mappedData[0]?.value);
        }
        return mappedData;
    };

    const filterDepositors = async (depositorType) => {
        let filteredList;


        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            filteredList = producerList?.filter((producer) => depositorType === producer.type && producer.entity_id === entity_id);
        }
        else
        {
            filteredList = producerList?.filter((producer) => depositorType === producer.type);
        }

        let mappedData = [];

        await Promise.all(
            filteredList?.map(async (data_to_map) => {
                const { label, value } = retreive_rSelectVariables(app_consts.PRODUCERS_LIST_MAP, data_to_map);
                const result = await rSelectMapFun(label, value);
                mappedData.push(result);
            })
        );
        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase() && mappedData)
        {
            setDepositorOwnlabel(mappedData[0]?.label);
            formik.setFieldValue('current_owner_id', mappedData[0]?.value);
        }
        return mappedData;
    };

    const MAX_FILE_SIZE_MB = 20;

    const handleFileChange = (e, fieldName) => {
        const selectedFile = e.target.files[0];

        if (selectedFile)
        {
            const fileSizeInMB = selectedFile.size / (1024 * 1024);
            if (fileSizeInMB > MAX_FILE_SIZE_MB)
            {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: `File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`,
                }));
                return;
            }

            const allowedFileTypes = [
                'image/jpeg',
                'image/jpg',
                'image/png',
                'application/pdf',
            ];

            if (allowedFileTypes.includes(selectedFile.type))
            {
                formik.setFieldValue("support_document", selectedFile);
                setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null, file: null }));
            } else
            {
                formik.setFieldValue("support_document", "");
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: "Please select a valid image or PDF file (PNG, JPEG, or PDF).",
                }));
            }
        }
    };

    return (
        <>
            {
                isOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
                        <div className="fixed inset-0 bg-black opacity-50"></div>
                        <div className="relative p-4 bg-white lg:w-4/12 sm:w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
                            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{page_action}</h3>
                                {
                                    !loading && (
                                        <>
                                            <button type="button" onClick={closeFunction} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                <span className="sr-only">Close modal</span>
                                            </button>
                                        </>
                                    )
                                }
                            </div>
                            <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                                {error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <span className="font-medium">Error: </span>{error}
                                </div>}
                                {/* Update Warehouse Status */}
                                {page_action === "Update Warehouse Status" && (
                                    <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                                        <div className="mb-6">
                                            <label htmlFor="warehouse_receipt_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Warehouse Status
                                            </label>
                                            <select
                                                name="warehouse_status"
                                                value={formik.values.warehouse_status}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            >
                                                <option value="">Select Warehouse Receipt status</option>
                                                {
                                                    module_data?.status !== app_consts.APPROVED && (
                                                        <option value={app_consts.APPROVED}>Approved</option>
                                                    )
                                                }
                                                {
                                                    module_data?.status !== app_consts.PENDING && (
                                                        <option value={app_consts.PENDING}>Pending</option>
                                                    )
                                                }
                                                {
                                                    module_data?.status !== app_consts.EXPIRED && (
                                                        <option value={app_consts.EXPIRED}>Expired</option>
                                                    )
                                                }
                                                {
                                                    module_data?.status !== app_consts.CANCELLED && (
                                                        <option value={app_consts.CANCELLED}>Cancelled</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {/* Update Negotiability */}
                                {
                                    page_action === "Update Negotiable Status" && (
                                        <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                                            <div className="mb-6">
                                                <label htmlFor="negotiable_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                    Negotiable Status
                                                </label>
                                                <select
                                                    name="negotiable_status"
                                                    value={formik.values.negotiable_status}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    required
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                >
                                                    <option value="">Select negotiable status</option>
                                                    {
                                                        module_data?.negotiable ? (
                                                            <>
                                                                <option value={false}>Non Negotiable</option>
                                                            </>
                                                        ) : (
                                                            <option value={true}>Negotiable</option>
                                                        )}
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }
                                {page_action === "Change Absolute Owner" && (
                                    <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                                        <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                                            <div>
                                                <label htmlFor="depositor_type"
                                                    className="flex flex-row justify-between block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    <span>Absolute Owner Type</span>
                                                    {
                                                        producerLoading && (
                                                            <>
                                                                <div className="text-center">
                                                                    <div role="status">
                                                                        <svg
                                                                            aria-hidden="true"
                                                                            className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                                            viewBox="0 0 100 101"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                fill="currentColor"
                                                                            />
                                                                            <path
                                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                fill="currentFill"
                                                                            />
                                                                        </svg>
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </label>
                                                <select
                                                    disabled={producerLoading}
                                                    name="depositor_type"
                                                    onChange={(e) => handleAbsoluteDepositorTypeChange(e.target.value)}
                                                    // required
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                >
                                                    <option value=''>Choose Depositor type</option>
                                                    <option value='main_entity'>Main Entity</option>
                                                    {/* <option value='individual'>Individual <small>(producer)</small></option> */}
                                                    <option value='commercial'>Commercial <small>(producer)</small></option>
                                                    <option value='public_entity'>Public Entity <small>(producer)</small></option>
                                                    <option value='broker'>Broker</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="absolute_owner_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                <span>New Absolute Owner</span>
                                                {
                                                    producerLoading && (
                                                        <>
                                                            <div className="text-center">
                                                                <div role="status">
                                                                    <svg
                                                                        aria-hidden="true"
                                                                        className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                                        viewBox="0 0 100 101"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                            fill="currentColor"
                                                                        />
                                                                        <path
                                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                            fill="currentFill"
                                                                        />
                                                                    </svg>
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </label>

                                            {depositor_type === "main_entity" ? (
                                                <RSelect
                                                    name="absolute_owner_id"
                                                    onChange={(e) => handleRSelectChange(e, "absolute_owner_id")}
                                                    options={entity_list}
                                                />
                                            ) : depositor_type === app_consts.WAREHOUSE_OPERATOR.toLowerCase() ? (
                                                <input
                                                    type="text"
                                                    name="absolute_owner_id"
                                                    disabled={producerLoading}
                                                    value={depositor_own_label || "Depositor Not Found"}
                                                    className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                />
                                            ) : (
                                                <RSelect
                                                    name="absolute_owner_id"
                                                    onChange={(e) => handleRSelectChange(e, "absolute_owner_id")}
                                                    options={selectedDepositorType}
                                                    isClearable={true}
                                                />
                                            )}

                                        </div>
                                    </div>
                                )}
                                {page_action === "Change Current Owner" && (
                                    <>
                                        <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                                            <div>
                                                <label htmlFor="depositor_type"
                                                    className="flex flex-row justify-between block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    <span>Current Owner Type</span>
                                                    {
                                                        producerLoading && (
                                                            <>
                                                                <div className="text-center">
                                                                    <div role="status">
                                                                        <svg
                                                                            aria-hidden="true"
                                                                            className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                                            viewBox="0 0 100 101"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                fill="currentColor"
                                                                            />
                                                                            <path
                                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                fill="currentFill"
                                                                            />
                                                                        </svg>
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </label>
                                                <select
                                                    disabled={producerLoading}
                                                    name="depositor_type"
                                                    onChange={(e) => handleDepositorTypeChange(e.target.value)}
                                                    // required
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                >
                                                    <option value=''>Choose Depositor type</option>
                                                    <option value='individual'>Individual</option>
                                                    <option value='commercial'>Commercial</option>
                                                    <option value='public_entity'>Public Entity</option>
                                                    <option value='broker'>Broker</option>
                                                    <option value='warehouse_operator'>Own</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor='profileType'
                                                className="flex flex-row justify-between block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                <span>New Current Owner</span>
                                                {
                                                    producerLoading && (
                                                        <>
                                                            <div className="text-center">
                                                                <div role="status">
                                                                    <svg
                                                                        aria-hidden="true"
                                                                        className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                                        viewBox="0 0 100 101"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                            fill="currentColor"
                                                                        />
                                                                        <path
                                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                            fill="currentFill"
                                                                        />
                                                                    </svg>
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </label>
                                            {
                                                depositor_type === app_consts.WAREHOUSE_OPERATOR.toLowerCase() ?
                                                    <>
                                                        <input
                                                            type='text'
                                                            name="current_owner_id"
                                                            isDisabled={producerLoading}
                                                            value={depositor_own_label || "Depositor Not Found"}
                                                            className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        <RSelect
                                                            name='current_owner_id'
                                                            // value={formik.values.current_owner_id}
                                                            onChange={(e) => { handleRSelectChange(e, "current_owner_id"); }}
                                                            options={selectedDepositorType}
                                                            isClearable={true}
                                                        />
                                                    </>
                                            }
                                        </div>
                                    </>
                                )}
                                {(page_action === "Change Absolute Owner" || page_action === "Change Current Owner" || page_action === "Update Negotiable Status") && (
                                    <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                                        <div className="mb-6">
                                            <label htmlFor="reason" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Reason for Change
                                            </label>
                                            <textarea
                                                name="reason"
                                                value={formik.values.reason}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500 resize-none"
                                                rows={3}
                                                required
                                                placeholder="Enter reason for change"
                                            />
                                        </div>
                                    </div>
                                )}
                                {
                                    page_action === "Process Outbound" && (
                                        <>
                                            <div className="container grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                                                {/* <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8"> */}

                                                <div>
                                                    <label htmlFor="gin_no" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        GIN No.  <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Optional)</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="gin_no"
                                                        value={formik.values.gin}
                                                        onChange={formik.handleChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    />
                                                    {formik.touched.gin_no && formik.errors.gin_no && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.gin_no}</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="truck_load_no" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Truck Load No. <span className="block ml-2 text-xs font-medium text-red-600 dark:text-red-600">*</span>
                                                    </label>
                                                    <input
                                                        step="0.01"
                                                        type="number"
                                                        min="0.00"
                                                        name="truck_load_no"
                                                        value={formik.values.truck_load_no}
                                                        onChange={formik.handleChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    />
                                                    {formik.touched.truck_load_no && formik.errors.truck_load_no && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.truck_load_no}</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="description" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        No. Of Bags <span className="block ml-2 text-xs font-medium text-red-600 dark:text-red-600">*</span>
                                                    </label>
                                                    <input
                                                        step="0.01"
                                                        type="number"
                                                        min="0.00"
                                                        name="no_of_bags"
                                                        value={formik.values.no_of_bags}
                                                        onChange={formik.handleChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    />
                                                    {formik.touched.no_of_bags && formik.errors.no_of_bags && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.no_of_bags}</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="description" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Quantity <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">{formik.values.commodity?.toLowerCase() === 'gold' ? 'Grams (g)' : '(Metric Tonnes)'}</span>
                                                    </label>
                                                    <input
                                                        step="0.01"
                                                        type="number"
                                                        min="0.00"
                                                        name="new_quantity"
                                                        value={formik.values.new_quantity}
                                                        onChange={formik.handleChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    />
                                                    {formik.touched.new_quantity && formik.errors.new_quantity && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.new_quantity}</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="dispatched_to" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Dispatched To: <span className="block ml-2 text-xs font-medium text-red-600 dark:text-red-600">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="dispatched_to"
                                                        value={formik.values.gin}
                                                        onChange={formik.handleChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    />
                                                    {formik.touched.dispatched_to && formik.errors.dispatched_to && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.dispatched_to}</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="date_dispatched" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Date Dispatched <span className="block ml-2 text-xs font-medium text-red-600 dark:text-red-600">*</span>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="date_dispatched"
                                                        value={formik.values.date_dispatched}
                                                        onChange={formik.handleChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    />
                                                    {formik.touched.date_dispatched && formik.errors.date_dispatched && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.date_dispatched}</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <label htmlFor="support_document" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Supporting Document <span className="block ml-2 text-xs font-medium text-red-600 dark:text-red-600">*</span>
                                                    </label>
                                                    <FileInput
                                                        name="support_document"
                                                        onChange={(e) => handleFileChange(e)}
                                                    />
                                                    {formik.touched.support_document && formik.errors.support_document && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.support_document}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <div className="flex justify-between space-x-4">
                                    <button
                                        type="button"
                                        onClick={closeFunction}
                                        className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className={`text-white ${loading ? "bg-gray-400 " : "bg-primary-600 hover:bg-primary-700 dark:focus:ring-primary-800"} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2`}
                                    >
                                        {loading ? 'Updating...' : page_action === "Process Outbound" ? "Process Outbound" : "Update"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
        </>
    );
};

export default WarehouseReceiptActionsModal;