import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import PDFSVGLogo from '../../components/PDFSVGLogo';
import { format, parseISO } from 'date-fns';

const ExchangeTradeFulfilmentsPDF = ({ pdf_data }) => {

  const [documentData, setDocumentData] = useState(pdf_data);
  const [fulfilment_data, setFulfilmentData] = useState([]);
  const account = JSON.parse(localStorage.getItem('account'));
  const firstName = account.user.first_name;
  const lastName = account.user.last_name;

  // Register the Lato font
  Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

  useEffect(() => {
    if (pdf_data)
    {
      setDocumentData(pdf_data);
    }
  }, [pdf_data]);

  useEffect(() => {
    if (documentData?.length > 0)
    {
      setFulfilmentData(groupedByLocation);
    }
  }, [documentData]);

  const groupedByLocation = documentData?.reduce((acc, item) => {
    const location = item?.fulfillment?.location;
    if (!acc[location])
    {
      acc[location] = [];
    }
    acc[location].push(item);
    return acc;
  }, {});

  const calculateTotalTradeConfirmationAmount = (list) => {
    const total = list.reduce((sum, item) => {
      const amount = parseFloat(item?.trade_confirmation?.amount) || 0;
      return sum + amount;
    }, 0);

    // Format the total with commas and two decimal places
    return total?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const calculateTotalTonnageQuantity = (list) => {
    const total = list.reduce((sum, item) => {
      const quantity = parseFloat(item?.trade_confirmation?.quantity) || 0;
      return sum + quantity;
    }, 0);

    // Format the total with commas and two decimal places
    return total.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };


  return (
    <>
      {
        fulfilment_data && (
          <Document title="Trade Fulfilments Statement">
            <Page size="A4" style={{
              flexDirection: "column",
              backgroundColor: "rgba(0,0,0,0)",
              height: "100%",
              fontFamily: "Lato"
            }}>
              <View fixed>
                <View style={{
                  width: "100%",
                  paddingRight: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 5,
                }}>
                  <View style={{
                    display: "flex",
                    flexDirection: "column",
                    ViewTransform: "uppercase",
                    width: "50%",
                    paddingLeft: 20,
                  }}>
                    <View style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", position: 'relative' }}>
                      <PDFSVGLogo height='100px' width='100%' />
                    </View>
                  </View>

                  <View style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    justifyContent: "end",
                    textTransform: "uppercase",
                    width: "50%",
                    marginRight: 10,
                  }}>
                    <View style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginBottom: 5,
                      marginTop: 5
                    }}>
                      <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 14 }}>Generated By:</Text>
                      <Text style={{ fontWeight: 600, fontSize: 14, color: "#252629", letterSpacing: 1, lineHeight: 1.2 }}>{`${firstName} ${lastName}`}</Text>
                    </View>
                    <View style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}>
                      <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 14 }}>Date Generated:</Text>
                      <Text style={{ fontWeight: 600, letterSpacing: 1, fontSize: 14, color: "#252629", lineHeight: 1.2 }}>{format(new Date(), 'yyyy-MM-dd  HH:mm:ss')}</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View fixed>
                <View style={{
                  width: "100%",
                  paddingRight: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 25,
                }}>
                  <Text style={{ fontWeight: 600, paddingLeft: 6, fontSize: 25, color: "#000", wordSpacing: "0.05px", lineHeight: 0.2, }}>
                    Trade Fulfilments Statement
                  </Text>
                </View>
              </View>

              <View style={{
                display: "flex",
                flexDirection: "row",
                ViewTransform: "uppercase",
                width: "100%",
                paddingLeft: 20,
                marginTop: 15
              }}>
                <View style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 5,
                  width: "50%"
                }}>
                  <Text style={{ fontWeight: 400, fontSize: 12, color: "#000" }}>Total Amount: </Text>
                  <Text style={{ fontWeight: 600, fontSize: 12, marginLeft: "4" }}>{`${calculateTotalTradeConfirmationAmount(documentData)} ZMW`}</Text>
                </View>
                <View style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 5,
                  width: "50%"
                }}>
                  <Text style={{ fontWeight: 400, fontSize: 12, color: "#000" }}>Total Tonnage: </Text>
                  <Text style={{ fontWeight: 600, fontSize: 12, marginLeft: "4" }}>{`${calculateTotalTonnageQuantity(documentData)} MT`}</Text>
                </View>
              </View>
              <View style={{ paddingRight: "30px", paddingLeft: 30, marginTop: 20 }}>
                <View style={{
                  color: "rgba(0, 0, 0, 0.87)",
                  marginTop: 5,
                  borderRadius: 4,
                  borderStyle: "solid",
                  borderColor: "#e5e9f2",
                }}>
                  {
                    Object.entries(fulfilment_data).map(([location, items]) => {
                      return (
                        <>
                          <View style={{
                            width: "100%",
                            paddingRight: 5,
                            paddingTop: 5,
                            paddingBottom: 5,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "start",
                            borderTopWidth: 2,
                            borderColor: "#e5e9f2",
                            borderStyle: "solid",
                          }}>
                            <View style={{
                              display: "flex",
                              flexDirection: "row",
                              ViewTransform: "uppercase",
                              width: "100%",
                              paddingLeft: 20,
                              marginTop: 15
                            }}>
                              <View style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%"
                              }}>
                                <Text style={{ fontWeight: 400, fontSize: 10, color: "#000" }}>LOCATION: </Text>
                                <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: "2" }}>{location}</Text>
                              </View>
                              <View style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "35%"
                              }}>
                                <Text style={{ fontWeight: 400, fontSize: 10, color: "#000" }}>Total Amount: </Text>
                                <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: "2" }}>{`${calculateTotalTradeConfirmationAmount(items)} ZMW`}</Text>
                              </View>
                              <View style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "35%"
                              }}>
                                <Text style={{ fontWeight: 400, fontSize: 10, color: "#000" }}>Total Tonnage: </Text>
                                <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: "2" }}>{`${calculateTotalTonnageQuantity(items)} MT`}</Text>
                              </View>
                            </View>
                            <View style={{
                              marginTop: 5,
                              marginBottom: 5,
                              width: "100%",
                            }}>
                              <View style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                borderTopWidth: 2,
                                borderStyle: "solid",
                                borderColor: "#D1D1D1",
                              }}>
                                <View style={{
                                  width: "16.67%",
                                  padding: 3,
                                }}>
                                  <Text style={{
                                    marginTop: 5,
                                    color: "#302f2f",
                                    fontSize: 12,
                                    lineHeight: 1.6,
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    textAlign: "start",
                                    padding: 3
                                  }}>Date</Text>
                                </View>
                                <View style={{
                                  width: "16.67%",
                                  padding: 3,
                                }}>
                                  <Text style={{
                                    marginTop: 5,
                                    color: "#302f2f",
                                    fontSize: 12,
                                    lineHeight: 1.6,
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    textAlign: "start",
                                    padding: 3
                                  }}>Commodity</Text>
                                </View>
                                <View style={{
                                  width: "16.67%",
                                  padding: 3,
                                }}>
                                  <Text style={{
                                    marginTop: 5,
                                    color: "#302f2f",
                                    fontSize: 12,
                                    lineHeight: 1.6,
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    textAlign: "end",
                                    padding: 3
                                  }}>Buyer</Text>
                                </View>
                                <View style={{
                                  width: "16.67%",
                                  padding: 3,
                                }}>
                                  <Text style={{
                                    marginTop: 5,
                                    color: "#302f2f",
                                    fontSize: 12,
                                    lineHeight: 1.6,
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    textAlign: "end",
                                    padding: 3
                                  }}>Quality</Text>
                                </View>
                                <View style={{
                                  width: "16.67%",
                                  padding: 3,
                                }}>
                                  <Text style={{
                                    marginTop: 5,
                                    color: "#302f2f",
                                    fontSize: 12,
                                    lineHeight: 1.6,
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    textAlign: "end",
                                    padding: 3
                                  }}>Quantity</Text>
                                </View>
                                <View style={{
                                  width: "16.67%",
                                  padding: 3,
                                }}>
                                  <Text style={{
                                    marginTop: 5,
                                    color: "#302f2f",
                                    fontSize: 12,
                                    lineHeight: 1.6,
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    textAlign: "end",
                                    padding: 3
                                  }}>Unit Price</Text>
                                </View>
                                <View style={{
                                  width: "16.67%",
                                  padding: 3,
                                }}>
                                  <Text style={{
                                    marginTop: 5,
                                    color: "#302f2f",
                                    fontSize: 12,
                                    lineHeight: 1.6,
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                    textAlign: "end",
                                    padding: 3
                                  }}>Total</Text>
                                </View>
                              </View>
                            </View>
                            {
                              items?.map((fulfilment) => {

                                return (
                                  <>
                                    <View style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      borderColor: "#e5e9f2",
                                      borderStyle: "solid",
                                      borderBottomWidth: 1,
                                    }}>
                                      <View style={{
                                        width: "16.67%",
                                        padding: 3,
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap", // Ensures text wraps within the container
                                          whiteSpace: "normal", // Allows text wrapping
                                        }}>
                                          {fulfilment?.trade_confirmation?.date_to_be_placed_on_platform ? format(parseISO(fulfilment?.trade_confirmation?.date_to_be_placed_on_platform), "yyyy-MM-dd") : "---"}
                                        </Text>
                                      </View>
                                      <View style={{
                                        width: "16.67%",
                                        padding: 3,
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap", // Ensures text wraps within the container
                                          whiteSpace: "normal", // Allows text wrapping
                                        }}>
                                          {fulfilment?.fulfillment?.commodity ? fulfilment?.fulfillment?.commodity.replace(/_/g, ' ').toUpperCase() : "---"}
                                        </Text>
                                      </View>
                                      <View style={{
                                        width: "16.67%",
                                        padding: 3,
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                        }}>
                                          {fulfilment?.buyer ? fulfilment?.buyer?.replace(/_/g, ' ')?.toUpperCase() : "---"}
                                        </Text>
                                      </View>
                                      <View style={{
                                        width: "16.67%",
                                        padding: 3,
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                        }}>
                                          {fulfilment?.warehouse_receipt?.quality ? fulfilment?.warehouse_receipt?.quality.replace(/_/g, ' ').toUpperCase() : "---"}
                                        </Text>
                                      </View>
                                      <View style={{
                                        width: "16.67%",
                                        padding: 3,
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                        }}>
                                          {fulfilment?.trade_confirmation?.quantity
                                            ? parseFloat(fulfilment?.trade_confirmation?.quantity)
                                              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            : "---"}
                                        </Text>
                                      </View>
                                      <View style={{
                                        width: "16.67%",
                                        padding: 3,
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                        }}>
                                          {fulfilment?.trade_confirmation?.unit_price
                                            ? parseFloat(fulfilment?.trade_confirmation?.unit_price)
                                              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            : "---"}
                                        </Text>
                                      </View>
                                      <View style={{
                                        width: "16.67%",
                                        padding: 3,
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                        }}>
                                          {fulfilment?.trade_confirmation?.amount
                                            ? parseFloat(fulfilment?.trade_confirmation?.amount)
                                              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            : "---"}
                                        </Text>
                                      </View>
                                    </View>

                                    <View style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      borderColor: "#e5e9f2",
                                      borderStyle: "solid",
                                      borderBottomWidth: 1,
                                    }}>
                                      <View style={{
                                        width: "50%",
                                        padding: 3,
                                        display: "flex",
                                        flexDirection: "row"
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                        }}>
                                          Receipt No:
                                        </Text>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 700,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                          marginLeft: 5
                                        }}>
                                          {fulfilment?.warehouse_receipt?.receipt_number ? fulfilment?.warehouse_receipt?.receipt_number?.replace(/_/g, ' ').toUpperCase() : "---"}
                                        </Text>
                                      </View>
                                      <View style={{
                                        width: "50%",
                                        padding: 3,
                                        display: "flex",
                                        flexDirection: "row"
                                      }}>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 400,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                        }}>
                                          Delivery Ref No:
                                        </Text>
                                        <Text style={{
                                          color: "#252b32",
                                          fontSize: 8,
                                          fontWeight: 700,
                                          textAlign: "start",
                                          padding: 3,
                                          flexWrap: "wrap",
                                          whiteSpace: "normal",
                                          marginLeft: 5
                                        }}>
                                          {fulfilment?.trade_confirmation?.transfer_no ? fulfilment?.trade_confirmation?.transfer_no?.replace(/_/g, ' ').toUpperCase() : "---"}
                                        </Text>
                                      </View>
                                    </View>
                                  </>
                                );
                              })
                            }
                          </View>
                        </>
                      );
                    })
                  }
                </View>
              </View>
            </Page>
          </Document>
        )
      }
    </>
  );
};

export default ExchangeTradeFulfilmentsPDF;