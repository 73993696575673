import React, { useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import NavLogo from '../../components/NavLogo';
import { getEntityProfileList, getOfferListFilterStatus, getOfferListFilterStatusReset, retrieve_valid_super_and_sub_offers_bids } from '../../utils/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderBanner from '../../layouts/HeaderBanner';
import * as app_consts from '../../utils/constants';
import TableComp from '../../components/table/TableComp';
import BrokerModuleSideMenu from '../../layouts/side_menus/BrokerModuleSideMenu';

const AtsDashboard = () => {
  const { entity_id, entity_mid, view } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [offers, setOffers] = useState([]);
  const [trades, setTrades] = useState('');
  const [bidtrades, setBidTrades] = useState('');
  const [totalTrades, setTotalTrades] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({ commodity: '', location: '', type: '', status: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const [profilesList, setProfilesList] = useState([]);

  // Retrive Data on Page Load
  useEffect(() => {
    fetchProfileList();
    dispatch(getOfferListFilterStatus(currentPage, pageSize));
    // dispatch(retrieve_valid_super_and_sub_offers_bids());
  }, []);

  const ats_listener = useSelector((state) => state.offerListStatusFiltered);

  // Listen to Dispatch 
  useEffect(() => {
    if (ats_listener.status !== app_consts.IDLE)
    {
      if (ats_listener.loading)
      {
        setLoading(true);
      }
      else
      {
        if (ats_listener.error)
        {
          setError(ats_listener.error);
        }
        else if (ats_listener.data)
        {
          setOffers(ats_listener.data?.data);
        }
        dispatch(getOfferListFilterStatusReset());
        setLoading(false);
      }
    }
  }, [ats_listener]);

  const resetFilters = () => {
    setSearchTerm('');
    setFilter({ commodity: '', location: '', type: '', status: '' });
  };

  const filterData = (item) => {
    const searchTermMatch = item.commodity?.toLowerCase().includes(searchTerm.toLowerCase());
    const commodityFilterMatch = filter.commodity === '' || item.commodity?.toLowerCase() === filter.commodity?.toLowerCase();
    const locationFilterMatch = filter.location === '' || item.location?.toLowerCase() === filter.location?.toLowerCase();
    const typeFilterMatch =
      filter.type === '' ||
      (item.order_type?.toLowerCase() === "super_bid" ? "BIDS" : item.order_type?.toLowerCase()) === filter.order_type ||
      (item.order_type?.toLowerCase() === "super_offer" ? "OFFERS" : item.order_type?.toLowerCase()) === filter.order_type;
    const statusFilterMatch = filter.status === '' || (item.status?.toLowerCase() === filter.status.toLowerCase());

    return searchTermMatch && commodityFilterMatch && locationFilterMatch && typeFilterMatch && statusFilterMatch;
  };

  const getAllCommodities = () => {
    const allCommodities = new Set();
    if (bidtrades)
    {
      bidtrades.forEach((bid) => {
        allCommodities.add(bid.commodity?.toLowerCase());
      });
    }
    if (trades)
    {
      trades.forEach((offer) => {
        allCommodities.add(offer.commodity?.toLowerCase());
      });
    }
    return Array.from(allCommodities);
  };

  const getAllLocations = () => {
    const allLocations = new Set();

    if (bidtrades)
    {
      bidtrades.forEach((bid) => {
        allLocations.add(bid.location);
      });
    }

    if (trades)
    {
      trades.forEach((offer) => {
        allLocations.add(offer.location);
      });
    }

    return Array.from(allLocations);
  };

  const getAllTypes = () => {
    const allTypes = new Set();

    if (bidtrades)
    {
      bidtrades.forEach((bid) => {
        const type = bid && bid.length > 0
          ? bid.order_type?.toLowerCase() === "super_bid"
            ? "BIDS"
            : ''
          : '';
        allTypes.add(type);
      });
    }

    if (trades)
    {
      trades.forEach((offer) => {
        const type = offer && offer.length > 0
          ? offer.order_type?.toLowerCase() === "super_offer"
            ? "OFFERS"
            : ''
          : '';
        allTypes.add(type);
      });
    }

    return Array.from(allTypes);
  };

  const getAllStatuses = () => {
    const allStatuses = new Set();

    if (bidtrades)
    {
      bidtrades.forEach((bid) => {
        const status = bid.status?.toLowerCase();
        allStatuses.add(status);
      });
    }

    if (trades)
    {
      trades.forEach((offer) => {
        const status = offer.status?.toLowerCase();
        allStatuses.add(status);
      });
    }

    return Array.from(allStatuses);
  };

  const filteredBrokerOfferData = trades
    ? trades?.filter((item) => filterData(item))
    : [];

  const filteredBrokerBidData = bidtrades
    ? bidtrades?.filter((item) => filterData(item))
    : [];


  const [modal, setModal] = useState({
    createBidModal: false,
    individual: false,
    organization: false,
  });

  const closeBrokerOfferModal = () => {
    setModal({ ...modal, brokerOfferModal: false });
  };

  const openSelectedModal = (modalToBeOpened) => {
    setModal({ ...modal, [modalToBeOpened]: true });
  };

  // Fetch Profile List
  const fetchProfileList = async () => {
    try
    {
      dispatch(getEntityProfileList(entity_id));
    } catch (error)
    {
      console.error(error);
    }
  };

  // Retrieve Profile List from State
  const profile_list = useSelector((state) => state.entityProfilesList);
  // 
  useEffect(() => {
    if (profile_list.data)
    {
      const selectedlist = profile_list.data?.find(
        (profile) => profile.member_type_id === entity_mid
      );
      setProfilesList(selectedlist);
    }
  }, [profile_list]);

  useEffect(() => {
    if (offers?.broker_offer?.length !== 0)
    {
      let tradeMap = [];


      offers?.broker_offer?.map((offer) => {
        offer?.commodity_order?.map((commodity_order) => {
          let bidsData = [];

          if (offer.broker_bids && offer.broker_bids.length > 0)
          {
            bidsData = offer.broker_bids.map((bid) => ({
              bid_id: bid.id,
              broker_name: bid.broker_name,
              bid: bid.bid,
              date_submitted_to_platform: bid.date_submitted_to_platform,
              date_to_be_placed_on_platform: bid.date_to_be_placed_on_platform,
              date_to_be_withdrawn_from_platform: bid.date_to_be_withdrawn_from_platform,
              commodity: bid.commodity,
              unit_of_measure: bid.unit_of_measure,
              volume: bid.volume,
              requested_unit_price: bid.requested_unit_price,
              quality: bid.quality,
              other_documentation_required: bid.other_documentation_required,
              order_type: bid.order_type,
              special_terms_and_conditions: bid.special_terms_and_conditions,
              broker_bid_reference_no: bid.broker_bid_reference_no,
              broker_bid_date: bid.broker_bid_date,
              location: bid.location,
              delivery: bid.delivery,
              commodity_order_id: bid.commodity_order_id,
              warehouse_receipt_id: bid.warehouse_receipt_id,
              participant_id: bid.participant_id,
              bid_volume: bid.bid_volume,
              bid_total: bid.bid_total,
              status: bid.status,
            }));
          }

          tradeMap.push({
            //offer data
            volume_on_offer: offer.volume_on_offer,
            broker_id: offer.broker_id,
            commodity: offer.commodity,
            date_submitted_to_platform: offer.date_submitted_to_platform,
            date_to_be_placed_on_platform: offer.date_to_be_placed_on_platform,
            delivery: offer.delivery,
            history: offer.history,
            id: offer.id,
            offer: offer.offer,
            location: offer.location,
            order_type: offer.order_type,
            other_documentation_required: offer.other_documentation_required,
            producer_id: offer.producer_id,
            quality: offer.quality,
            requested_unit_price: offer.requested_unit_price,
            special_terms_and_conditions: offer.special_terms_and_conditions,
            status: offer.status,
            volume: offer.volume,
            warehouse_receipt_id: offer.warehouse_receipt_id,

            //commodity order data
            commodity_order_status: commodity_order?.status,
            commodity_order_reference_no:
              commodity_order.commodity_order_reference_no,
            commodity_order_id: commodity_order.id,
            commodity_order_type: commodity_order.type,

            //bids data
            bids: bidsData,
          });
        });
      });

      setTrades(tradeMap);
    }
  }, [offers]);



  useEffect(() => {
    if (offers?.broker_bids?.length !== 0)
    {
      let tradeBidsMap = [];

      offers?.broker_bids?.map((bid) => {
        bid?.commodity_order?.map((commodity_order) => {
          let brokerOffersData = [];


          if (bid.broker_offers && bid.broker_offers.length > 0)
          {
            brokerOffersData = bid.broker_offers.map((brokerOffer) => ({
              broker_id: brokerOffer.broker_id,
              broker_offer_date: brokerOffer.broker_offer_date,
              broker_offer_reference_no: brokerOffer.broker_offer_reference_no,
              commodity: brokerOffer.commodity,
              date_submitted_to_platform: brokerOffer.date_submitted_to_platform,
              date_to_be_placed_on_platform: brokerOffer.date_to_be_placed_on_platform,
              date_to_be_withdrawn_from_platform: brokerOffer.date_to_be_withdrawn_from_platform,
              delivery: brokerOffer.delivery,
              history: brokerOffer.history,
              id: brokerOffer.id,
              inserted_at: brokerOffer.inserted_at,
              location: brokerOffer.location,
              offer: brokerOffer.offer,
              offer_owner: brokerOffer.offer_owner,
              offer_total: brokerOffer.offer_total,
              offer_volume: brokerOffer.offer_volume,
              order_type: brokerOffer.order_type,
              other_documentation_required: brokerOffer.other_documentation_required,
              producer_id: brokerOffer.producer_id,
              quality: brokerOffer.quality,
              requested_unit_price: brokerOffer.requested_unit_price,
              special_terms_and_conditions: brokerOffer.special_terms_and_conditions,
              status: brokerOffer.status,
              unit_of_measure: brokerOffer.unit_of_measure,
              volume: brokerOffer.volume,
              volume_on_offer: brokerOffer.volume_on_offer,
              warehouse_receipt_id: brokerOffer.warehouse_receipt_id,
            }));
          }

          tradeBidsMap.push({
            // bid properties
            bid: bid.bid,
            bid_total: bid.bid_total,
            bid_volume: bid.bid_volume,
            volume: bid.volume,
            location: bid.location,
            delivery: bid.delivery,
            requested_unit_price: bid.requested_unit_price,
            bid_volume: bid.bid_volume,
            date_to_be_placed_on_platform: bid.date_to_be_placed_on_platform,
            commodity: bid.commodity,
            id: bid.id,
            status: bid.status,
            broker_bid_date: bid.broker_bid_date,
            broker_bid_reference_no: bid.broker_bid_reference_no,
            broker_id: bid.broker_id,
            broker_name: bid.broker_name,
            special_terms_and_conditions: bid.special_terms_and_conditions,

            // broker offers data
            broker_offers: brokerOffersData,

            // commodity order data
            commodity_order_status: commodity_order?.status,
            commodity_order_reference_no: commodity_order?.commodity_order_reference_no,
            commodity_order_id: commodity_order?.id,
            commodity_order_type: commodity_order.type,


          });
        });
      });

      setBidTrades(tradeBidsMap);
    }
  }, [offers]);

  const brokerUrlPrefix = () => {
    if (view)
    {
      return 'broker/view';
    }
    else
    {
      return 'broker';
    }
  };

  return (
    <>
      <div>
        <BrokerModuleSideMenu brokerUrlPrefix={brokerUrlPrefix} entity_id={entity_id} entity_mid={entity_mid} activeLabel="Live Market" />

        <div className='p-4 sm:ml-64'>
          <div className='p-4 rounded-lg dark:border-gray-700'>
            <HeaderBanner
              title='Automated Trading System'
              type={app_consts.AUTOMATED_TRADING_SYSTEM}
              home={`home`}
              navInfo={{ entity_id, entity_mid }}
              header_addition={profilesList && profilesList.entity_name ? profilesList.entity_name : ""}
            />
            <div className='relative'>
              <div class='w-full md:w'>
                <h1 class='text-4xl font-bold dark:text-white'>
                  Commodity Exchange
                  <small class='ml-2 font-semibold text-gray-400 dark:text-gray-300'>
                    Current Listings
                  </small>
                </h1>
                <br />
                <div className="pb-4 bg-white dark:bg-gray-900 flex items-center">
                  <label htmlFor="table-search" className="sr-only">Search</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      placeholder="Search for a commodity"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <div className="relative ml-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg className="w-5 h-5 text-blue-500" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
                      </svg>
                    </div>
                    <select
                      className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      onChange={(e) => setFilter({ ...filter, commodity: e.target.value })}
                    >
                      <option value="">Filter By Commodity</option>
                      <option value="">ALL</option>
                      {getAllCommodities().map((commodity) => (
                        <option key={commodity} value={commodity}>
                          {commodity?.replace(/_/g, ' ').toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="relative ml-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-blue-500"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
                      </svg>
                    </div>
                    <select
                      className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                    >
                      <option value="">Filter By Type</option>
                      <option value="">ALL</option>
                      {getAllTypes().map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="relative ml-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg className="w-5 h-5 text-blue-500" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
                      </svg>
                    </div>
                    <select
                      className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                    >
                      <option value="">Filter By Status</option>
                      <option value="">ALL</option>
                      {getAllStatuses().map((status) => (
                        <option key={status} value={status}>
                          {status?.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="relative ml-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-blue-500"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
                      </svg>
                    </div>
                    <select
                      className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      onChange={(e) => setFilter({ ...filter, location: e.target.value })}
                    >
                      <option value="">Filter By Location</option>
                      <option value="">ALL</option>
                      {getAllLocations().map((location) => (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 ml-3"
                    onClick={resetFilters}
                  >
                    Reset Filters
                  </button>
                </div>
                {
                  loading ?
                    <>
                      <div className="text-center mt-4 h-10">
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Please wait...</span>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      {
                        error ?
                          <>
                            <tr className='flex items-center w-full justify-center'>
                              <td colSpan="8" className="w-full text-center py-4">
                                <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                </div>
                                <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                  {error}
                                </span>
                              </td>
                            </tr>
                          </>
                          :
                          <>
                            <TableComp tableData={filteredBrokerOfferData.concat(filteredBrokerBidData)} tableType={app_consts.ATS_MANAGEMENT} tableHeading="Commodity Orders" />
                          </>
                      }
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AtsDashboard;
