import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicNavLogo from '../../../../components/DynamicNavLogo';
import NavLogo from '../../../../components/NavLogo';
import CustomTabs from '../../../../layouts/CustomTabs';
import Icon from '../../../../components/Icon';
import { retreiveExchangeAllTradeFulfillments, retreiveExchangeMarketsReportsOverview, retrieveExchangeAllTradeFulfillmentsReset, retrieveExchangeMarketReportOverviewReset } from '../../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import * as app_consts from '../../../../utils/constants';
import TableComp from '../../../../components/table/TableComp';
import GeneratePDFModal from '../../../../components/modals/GeneratePDFModal';

const ExchangeMarketsReportsDashboard = () => {

  const { entity_id, entity_mid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('Overview');
  const [overview_loading, setOverviewLoading] = useState(true);
  const [overview_error, setOverviewError] = useState("");
  const [overview_data, setOverviewData] = useState("");
  const [openDropDown, setOpenDropdown] = useState(false);
  const [generatePDFIsOpen, setGeneratePDFIsOpen] = useState(false);

  const [all_transactions_loading, setAllTransactionsLoading] = useState(true);
  const [all_transactions_error, setAllTransactionsError] = useState("");
  const [all_transactions_data, setAllTransactionsData] = useState("");

  const [fulfilled_transactions_loading, setFulfilledTransactionsLoading] = useState(true);
  const [fulfilled_transactions_error, setFulfilledTransactionsError] = useState("");
  const [fulfilled_transactions_data, setFulfilledTransactionsData] = useState("");
  const [current_fulfilled_transactions_data, setCurrentFulfilledTransactionsData] = useState("");


  const tabs = [
    { title: 'Overview', disabled: overview_loading },
    { title: 'All Transactions', disabled: all_transactions_loading },
    { title: 'Fulfilled Transactions', disabled: fulfilled_transactions_loading },
  ];

  // Code to Run When Page Opens
  useEffect(() => {
    dispatch(retreiveExchangeMarketsReportsOverview());
    dispatch(retreiveExchangeAllTradeFulfillments());
  }, []);

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  // Listener
  const data_overview_listener = useSelector((state) => state.retrieveExchangeMarketReportsOverview);
  // 
  useEffect(() => {
    if (data_overview_listener.status !== app_consts.IDLE)
    {
      if (data_overview_listener.isLoading)
      {
        setOverviewLoading(true);
      }
      else
      {
        if (data_overview_listener.error)
        {
          setOverviewError(data_overview_listener.error);
        }
        else if (data_overview_listener.data)
        {
          setOverviewData(data_overview_listener.data);
        }
        dispatch(retrieveExchangeMarketReportOverviewReset());
        setOverviewLoading(false);
      }
    }
  }, [data_overview_listener]);

  // Trade Fulfillments Listener
  const trade_fulfillments_listener = useSelector((state) => state.retrieveExchangeAllTradeFulfillments);
  // 
  useEffect(() => {
    if (trade_fulfillments_listener.status !== app_consts.IDLE)
    {
      if (trade_fulfillments_listener.isLoading)
      {
        setFulfilledTransactionsLoading(true);
      }
      else
      {
        if (trade_fulfillments_listener.error)
        {
          setFulfilledTransactionsError(trade_fulfillments_listener.error);
        }
        else if (trade_fulfillments_listener.data)
        {
          setFulfilledTransactionsData(trade_fulfillments_listener.data);
          setCurrentFulfilledTransactionsData(trade_fulfillments_listener.data);
        }
        dispatch(retrieveExchangeAllTradeFulfillmentsReset());
        setFulfilledTransactionsLoading(false);
      }
    }
  }, [trade_fulfillments_listener]);



  return (
    <>
      <button
        data-drawer-target='logo-sidebar'
        data-drawer-toggle='logo-sidebar'
        aria-controls='logo-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clip-rule='evenodd'
            fill-rule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
        <NavLogo />
      </button>

      <nav class="flex items-center justify-between flex-wrap p-6 bg-gray-50">
        <div class="flex items-center flex-shrink-0  mr-6">

          <a href='/home' className='flex items-center pl-2.5'>
            <DynamicNavLogo height='50' width='150' />
          </a>
        </div>
        <div class="block lg:hidden">
          <button class="flex items-center px-3 py-2 border rounded hover:text-white hover:border-white">
            <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div class="text-sm lg:flex-grow">
            <a href={`/exchange/${entity_id}/${entity_mid}`} class="text-lg flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white  group font-semibold">
              Market Dashboard (Exchange)
            </a>
          </div>
          <div>
            <a href="#" onClick={handleLogout} class="inline-block text-sm px-4 py-2 leading-none border border-white hover:border-transparent  hover:bg-white mt-4 lg:mt-0">Logout</a>
          </div>
        </div>
      </nav>

      <div className="lg-full lg:w-10/12 m-auto">
        <div className="p-4 rounded-lg dark:border-gray-700">

          <div className="relative">
            <div>
              <h5 class="text-3xl mr-3 font-semibold dark:text-white">Market Reports </h5>
            </div>
            <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            {
              activeTab === "Overview" && (
                <>
                  {
                    overview_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Market Reports Overview
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          overview_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {overview_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              <div class="mt-2 mx-auto max-w-full px-4 sm:px-6 lg:px-8">
                                <div
                                  class="rounded-2xl py-10 px-8 xl:py-14 xl:px-16 bg-gray-100 shadow-lg flex flex-col lg:flex-row items-center gap-10 lg:gap-16"
                                >

                                  <div class="w-full lg:w-1/3 text-center lg:text-left">
                                    <h2 class="font-manrope text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                                      Market Overview
                                    </h2>
                                    <p class="text-sm lg:text-base text-gray-600 leading-6">
                                      Total Bids and Offers Placed
                                    </p>
                                  </div>


                                  <div class="w-full lg:w-2/3">
                                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">

                                      <div class="flex flex-col items-center lg:items-start p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                        <div class="font-manrope text-4xl font-bold text-green-600 mb-2">
                                          {overview_data?.all_brokers_super_offers}
                                        </div>
                                        <span class="text-gray-800 text-sm lg:text-base">Total Main Offers</span>
                                      </div>


                                      <div class="flex flex-col items-center lg:items-start p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                        <div class="font-manrope text-4xl font-bold text-green-600 mb-2">
                                          {overview_data?.all_brokers_sub_offers}
                                        </div>
                                        <span class="text-gray-800 text-sm lg:text-base">Total Offers on Bids</span>
                                      </div>


                                      <div class="flex flex-col items-center lg:items-start p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                        <div class="font-manrope text-4xl font-bold text-green-600 mb-2">
                                          {overview_data?.all_broker_super_bids}
                                        </div>
                                        <span class="text-gray-800 text-sm lg:text-base">Total Main Bids</span>
                                      </div>


                                      <div class="flex flex-col items-center lg:items-start p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
                                        <div class="font-manrope text-4xl font-bold text-green-600 mb-2">
                                          {overview_data?.all_broker_sub_bids}
                                        </div>
                                        <span class="text-gray-800 text-sm lg:text-base">
                                          Total Bids On Offer
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                        }
                      </>
                  }
                </>
              )
            }
            {
              activeTab === "All Transactions" && (
                <>
                  {
                    all_transactions_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading All Transactions
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          all_transactions_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {all_transactions_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>

                            </>
                        }
                      </>
                  }
                </>
              )
            }
            {
              activeTab === "Fulfilled Transactions" && (
                <>
                  {
                    fulfilled_transactions_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Fulfilled Transactions
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          fulfilled_transactions_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {fulfilled_transactions_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>
                              <div className="w-11/12 mx-auto p-4">
                                <h5 className="text-center text-3xl my-4 font-extrabold text-gray-800 dark:text-white relative">
                                  Trade Fulfillments
                                  <span className="block mt-2 w-20 h-1 bg-green-500 mx-auto"></span>
                                </h5>
                              </div>

                              <div className="flex justify-end mb-4">
                                <div className="relative">
                                  <button
                                    className="flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                    type="button"
                                    onClick={() => setGeneratePDFIsOpen(!generatePDFIsOpen)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-5 h-5 mr-2"
                                    >
                                      <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                                    </svg>
                                    Generate PDF
                                  </button>
                                </div>
                              </div>

                              <TableComp tableData={current_fulfilled_transactions_data} tableType={app_consts.EXCHANGE_TRADE_FULFILLMENTS_LIST_TABLE} tableHeading="Trade Fulfillments" />
                            </>
                        }
                      </>
                  }
                  <GeneratePDFModal isOpen={generatePDFIsOpen} closeUploadModal={() => setGeneratePDFIsOpen(!generatePDFIsOpen)} pdfData={current_fulfilled_transactions_data} docName="Trade Fulfilments PDF" type={app_consts.EXCHENGE_TRADE_FULFILMENT_PDF} />
                </>
              )
            }
          </div>
          <div className="flex justify-center mt-4">
            <Icon opacity={0.5} height="30mm" width="40mm" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExchangeMarketsReportsDashboard;