import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavLogo from '../components/NavLogo';

const NavHeader = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <header>
            <nav className="bg-white border-b border-gray-200 px-4 lg:px-6 py-3 dark:bg-gray-800">
                <div className="flex justify-between items-center">
                    <div className="flex justify-start items-center">
                        <NavLogo />
                    </div>

                    {/* Mobile Hamburger Icon */}
                    <div className="lg:hidden">
                        <button onClick={toggleDropdown} aria-label="Toggle menu">
                            <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                {dropdownOpen ? (
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                ) : (
                                    <path
                                        fillRule="evenodd"
                                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        clipRule="evenodd"
                                    />
                                )}
                            </svg>
                        </button>
                    </div>

                    {/* Menu Items */}
                    <div className={`flex items-center ${dropdownOpen ? 'block' : 'hidden'} lg:flex`}>
                        <button
                            onClick={handleLogout}
                            className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-gray-500 mr-2 mb-2"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default NavHeader;
