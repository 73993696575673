import React, { useEffect, useState } from 'react';
import ExchangeTradeFulfilmentsPDF from '../../layouts/generated_PDFs/ExchangeTradeFulfilmentsPDF';
import * as app_consts from '../../utils/constants';
import { PDFViewer } from '@react-pdf/renderer';
import WarehouseOperatorInventoryPDF from '../../layouts/generated_PDFs/WarehouseOperatorInventoryPDF';

const GeneratePDFModal = ({ isOpen, docName, pdfData, type, closeUploadModal }) => {


  const [isModalUploadOpen, setIsUploadModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState(docName);
  const [documentData, setDocumentData] = useState(pdfData);

  useEffect(() => {
    setDocumentName(docName);
    setIsUploadModalOpen(isOpen);
    setDocumentData(pdfData);
  }, [isOpen, docName]);

  return (
    <>
      {
        isModalUploadOpen && (
          <div className="fixed inset-4 flex items-center justify-center z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            {/* Modal content goes here */}
            <div className="relative p-8 bg-white rounded-lg shadow dark:bg-gray-800 max-h-screen overflow-y-auto w-full max-w-screen-lg">
              {/* Add your modal content here */}
              <div class="flex justify-between w-full px-6 items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {documentName}
                </h3>
                <button type="button" onClick={closeUploadModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                  <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Close</span>
                </button>
              </div>
              <div className="border border-gray-200 h-auto">
                <div style={{ height: "80vh", width: "100%", overflow: "scroll", margin: "auto" }} className='border-2 border-text-gray-400'>
                  <PDFViewer height="100%" width="100%" showToolbar>
                    {
                      type === app_consts.EXCHENGE_TRADE_FULFILMENT_PDF ?
                        <>
                          <ExchangeTradeFulfilmentsPDF pdf_data={documentData} />
                        </>
                        :
                        type === app_consts.WAREHOUSE_OPERATOR_INVENTORY_PDF ?
                          <>
                            <WarehouseOperatorInventoryPDF pdf_data={documentData} />
                          </>
                          :
                          <>

                          </>
                    }
                  </PDFViewer>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default GeneratePDFModal;