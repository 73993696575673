import React, { useEffect, useState } from 'react';
import Logo from '../../../components/Logo';
import NavLogo from '../../../components/NavLogo';
import { getBrokerProducers, getBrokerProducersReset, getEntityProfileList, registerNewMember } from '../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countries from '../../../utils/countries.json';
import provinces from '../../../utils/provinces.json';
import HeaderBanner from '../../../layouts/HeaderBanner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as app_consts from '../../../utils/constants';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import CustomerTypeModal from '../../../components/modals/CustomerTypeModal';
// import IndividualCustomerModal from '../../../components/modals/IndividualCustomerModal';
// import OrganizationCustomerModal from '../../../components/modals/OrganizationCustomerModal';
import CustomerModal from '../../../components/modals/CustomerModal';
import { addThreeDots } from '../../../utils/functionalUtils';
import TableComp from '../../../components/table/TableComp';
import BrokerModuleSideMenu from '../../../layouts/side_menus/BrokerModuleSideMenu';
import Icon from '../../../components/Icon';

const CustomerManagement = () => {
  const { entity_id, entity_mid, view } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilesList, setProfilesList] = useState([]);

  const [producers, setProducers] = useState([]);
  const [current_producers, setCurrentProducers] = useState([]);

  const [totalProducers, setTotalProducers] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const [modal, setModal] = useState({
    customerModal: false,
    individual: false,
    organization: false,
  });

  const closeCustomerModal = () => {
    setModal({ ...modal, customerModal: false });
  };

  const openSelectedModal = (modalToBeOpened) => {
    setModal({ ...modal, [modalToBeOpened]: true });
  };

  useEffect(() => {
    fetchProducers();
    fetchProfileList();
  }, []);

  const fetchProducers = () => {
    dispatch(getBrokerProducers(entity_id));
  };

  // Fetch Profile List
  const fetchProfileList = async () => {
    try
    {
      dispatch(getEntityProfileList(entity_id));
    } catch (error)
    {
      console.error(error);
    }
  };

  // Retrieve Profile List from State
  const profile_list = useSelector((state) => state.entityProfilesList);
  // 
  useEffect(() => {
    if (profile_list.data)
    {
      const selectedlist = profile_list.data?.find(
        (profile) => profile.member_type_id === entity_mid
      );
      setProfilesList(selectedlist);
    }
  }, [profile_list]);

  // Listen to Changes from Fetch Producer Reducer
  const fetch_broker_producers_listener = useSelector((state) => state.producerList);
  // 
  useEffect(() => {
    if (fetch_broker_producers_listener.status !== app_consts.IDLE)
    {
      if (fetch_broker_producers_listener.loading)
      {
        setLoading(true);
      }
      else
      {
        if (fetch_broker_producers_listener.error)
        {
          setError(fetch_broker_producers_listener.error);
        }
        else if (fetch_broker_producers_listener.data)
        {
          setProducers(fetch_broker_producers_listener.data);
          setCurrentProducers(fetch_broker_producers_listener.data);
          setTotalProducers(fetch_broker_producers_listener.data?.length);
        }
        dispatch(getBrokerProducersReset());
        setLoading(false);
      }
    }
  }, [fetch_broker_producers_listener]);


  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;


  const filteredProducers = () => {
    let displayedProducers;
    displayedProducers = producers?.filter((broker_customer) => {
      const combinedParams = [
        broker_customer?.producer?.type,
        broker_customer?.producer?.contact_name,
        broker_customer?.producer?.contact_email,
        broker_customer?.producer?.contact_phone,
        broker_customer?.producer?.province,
        broker_customer?.producer?.country,
        broker_customer?.producer_entity?.producer_entity_name,
      ]
        .join(' ')
        .toLowerCase();

      return combinedParams.includes(searchTerm?.toLowerCase());
    }).slice(startIndex, endIndex);


    setCurrentProducers(displayedProducers || []);
  };

  // UseEffect for Search 
  useEffect(() => {
    if (searchTerm) 
    {
      filteredProducers();
    }
    else
    {
      filteredProducers();
    }
  }, [searchTerm]);

  // URL Prefixes
  const brokerUrlPrefix = () => {
    if (view)
    {
      return 'broker/view';
    }
    else
    {
      return 'broker';
    }
  };

  return (
    <div>
      <BrokerModuleSideMenu brokerUrlPrefix={brokerUrlPrefix} entity_id={entity_id} entity_mid={entity_mid} activeLabel="Clients" />

      <div className='p-4 sm:ml-64'>
        <div className='p-4 rounded-lg dark:border-gray-700'>
          <HeaderBanner
            title='Client Management'
            type={app_consts.CUSTOMER_MANAGEMENT}
            home={`home`}
            header_addition={profilesList && profilesList.entity_name ? profilesList.entity_name : ""}
            navInfo={{ entity_id, entity_mid }}
          />
          <div class='w-full relative bg-white dark:bg-gray-800 sm:rounded-lg'>
            <div class='flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4'>
              <div class='w-full md:w-1/2'>
                <form class='flex items-center'>
                  <label for='simple-search' class='sr-only'>
                    Search
                  </label>
                  <div class='relative w-full'>
                    <div class='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <svg
                        aria-hidden='true'
                        class='w-5 h-5 text-gray-500 dark:text-gray-400'
                        fill='currentColor'
                        viewbox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                          clip-rule='evenodd'
                        />
                      </svg>
                    </div>
                    <input
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      type='text'
                      id='simple-search'
                      class='block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                      placeholder='Search'
                      required=''
                    />
                  </div>
                </form>
              </div>
              <div class='flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3'>
                {
                  !view && (
                    <>
                      <button
                        type='button'
                        onClick={() => setModal({ customerModal: true })}
                        className='flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          className='w-5 h-5 mr-2'
                        >
                          <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                          <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                        </svg>
                        Register New Client
                      </button>
                    </>
                  )
                }
              </div>
            </div>
          </div>
          <div className='relative'>
            <br />
            {
              loading ?
                <>
                  <div className="text-center mt-4 h-10">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Please wait...</span>
                    </div>
                  </div>
                </>
                :
                <>
                  {
                    error ?
                      <>
                        <tr className='flex items-center w-full justify-center'>
                          <td colSpan="8" className="w-full text-center py-4">
                            <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </div>
                            <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                              {error}
                            </span>
                          </td>
                        </tr>
                      </>
                      :
                      <>
                        <TableComp tableData={current_producers} tableType={app_consts.BROKER_PRODUCERS} tableHeading="Broker Clients" />
                      </>
                  }
                </>
            }
          </div>

          <div className="flex justify-center mt-4">
            <Icon opacity={0.5} height="30mm" width="40mm" />
          </div>
        </div>
      </div>

      <CustomerModal
        showModal={modal.customerModal}
        closeModal={closeCustomerModal}
        openSelectedModal={openSelectedModal}
        fetchProducers={fetchProducers}
      />

      {/* <IndividualCustomerModal
        showModal={modal.individual}
        closeModal={closeIndividualModal}
        // onSubmit={registerIndividualCustomer}
      />

      <OrganizationCustomerModal
        showModal={modal.organization}
        closeModal={closeOrganizationModal}
        // onSubmit={registerOrganizationCustomer}
      /> */}
    </div>
  );
};

export default CustomerManagement;
