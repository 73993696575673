import React from 'react';
import { FaBars, FaSignOutAlt, FaUsers, FaWarehouse } from 'react-icons/fa';
import { MdBarChart, MdDashboard } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';
import { FiMapPin, FiShuffle } from 'react-icons/fi';
import { HiOutlineClipboardCheck, HiOutlineMap } from 'react-icons/hi';
import { RiUserSettingsLine } from 'react-icons/ri';

const WarehouseOperatorModuleSideMenu = ({ warehouseUrlPrefix, entity_id, entity_mid, activeLabel }) => {
 const navigate = useNavigate();

 const handleLogout = () => {
  localStorage.clear();
  navigate('/');
 };

 const SidebarItem = ({ href, label, Icon, isActive, extraContent }) => (
  <li>
   <a
    href={href}
    className={`flex items-center p-2 ${isActive ? 'text-green-500 bg-green-100' : 'text-gray-500 hover:text-green-500'
     } rounded-lg hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 group`}
   >
    <Icon className="w-6 h-6" />
    <span className="ml-3">{label}</span>
    {extraContent && <span className="ml-auto">{extraContent}</span>}
   </a>
  </li>
 );

 return (
  <>
   <div>
    <button
     data-drawer-target="logo-sidebar"
     data-drawer-toggle="logo-sidebar"
     aria-controls="logo-sidebar"
     type="button"
     className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    >
     <FaBars className="w-6 h-6" />
     <span className="sr-only">Open sidebar</span>
    </button>

    <aside
     id="logo-sidebar"
     className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
     aria-label="Sidebar"
    >
     <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
      <a href="/" className="flex items-center pl-2.5 mb-5">
       <Logo />
      </a>

      <ul className="space-y-2 font-medium">
       <SidebarItem
        href="/home"
        label="My Dashboards"
        Icon={MdDashboard}
        isActive={activeLabel === 'My Dashboards'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}`}
        label="My Dashboards"
        Icon={FaWarehouse}
        isActive={activeLabel === 'Warehouse Dashboard'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/locations`}
        label="Warehouse Locations"
        Icon={FiMapPin}
        isActive={activeLabel === 'Warehouse Locations'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/inbound_shipments`}
        label="Inbound Shipments"
        Icon={FiShuffle}
        isActive={activeLabel === 'Inbound Shipments'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts`}
        label="Warehouse Receipts"
        Icon={HiOutlineMap}
        isActive={activeLabel === 'Warehouse Receipts'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipt_depositors`}
        label="WHR Depositors"
        Icon={FaUsers}
        isActive={activeLabel === 'WHR Depositors'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/trade_fulfillments`}
        label="Trade Fulfillments"
        Icon={HiOutlineClipboardCheck}
        isActive={activeLabel === 'Trade Fulfillments'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/reports`}
        label="Warehouse Reports"
        Icon={MdBarChart}
        isActive={activeLabel === 'Warehouse Reports'}
       />
       <SidebarItem
        href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/profile`}
        label="Warehouse Profile"
        Icon={RiUserSettingsLine}
        isActive={activeLabel === 'Warehouse Profile'}
       />
       <li className="my-2 fixed bottom-12 w-11/12">
        <a
         onClick={handleLogout}
         className="cursor-pointer flex items-center p-2 text-gray-500 hover:text-red-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
         <FaSignOutAlt className="w-6 h-6" />
         <span className="ml-3">Logout</span>
        </a>
       </li>
      </ul>
     </div>
    </aside>
   </div>

  </>
 );
};

export default WarehouseOperatorModuleSideMenu;