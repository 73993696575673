import React, { useEffect, useState } from 'react';
import * as app_consts from "../../utils/constants";
import { table_columns } from './table_columns';
import { BidManagemenetTable, BrokerOfferTradeTable, EntityProfileUserProfilesTable, OfferHistoryTable, SuperBidManagemenetTable, TradeFulfillmentListTable, ProvisionReceiptListTable, WarehouseReceiptHistoryTable, WarehouseReceiptListTable, WarehouseReportsTable, WarehouseLocationsListTable, InboundShipmentsTable, GRNInfoTable, ProducerWarehouseReceiptsTable, SpecificProducerWarehouseReceiptsTable, SpecificWarehouseReceiptsDepositorsGRNTable, ReportsAuditTrailsTable, ExchangeProducerListTable, ExchangeProducerBrokersTable, BrokerProducersTable, AtsManagementTable, MemberShipManagement, EntityProfilesExchangeTable, BankAccountsTable, UserProfilesTable, ExchangeWarehouseOperatorTable, ExchangeWarehouseLocationsListTable, ExchangeBrokersTable, ExchangeBrokerDetailsSuperOffersTable, SpecificUserProfilesTable, ExchangeBrokerDetailsSuperBidsTable, ExchangeCooperativesTable, ExchangeEntityProducersTable, UsersTable, UserAccountProfileTable, UserAccountBrokersCooperatives, ValidCooperativeEntityCSVDataTable, InvalidCooperativeEntityCSVDataTable, InvalidCooperativeMemberCSVDataTable, ValidCooperativeMemberCSVDataTable, BrokerBidTradeTable, BrokerSuperBidListTable, BrokerSubBidListTable, BrokerSuperOfferListTable, BrokerSubOffersListTable, ExchangeTradeFullfilmentListTable, WarehouseOperatorInventoryListTable } from './TableRows';

const TableComp = ({ tableData, tableHeading, tableType, checkboxRow, rowAction }) => {

    const [data, setData] = useState([]);
    const [heading, setHeading] = useState("");
    const [type, setType] = useState("");
    const [columns, setColumns] = useState(table_columns(tableType));
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(20);
    const [totalDataSet, setTotalDataSet] = useState(0);


    useEffect(() => {
        setData(tableData);
        setTotalDataSet(tableData?.length);
        setCurrentPage(1);
    }, [tableData]);

    useEffect(() => {
        setType(tableType);
        setColumns(table_columns(tableType));
    }, [type]);

    // Get current items based on pagination
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate the start and end index for the current page
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Pagination handlers
    const nextPage = () => {
        if (currentPage * pageSize < totalDataSet)
        {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1)
        {
            setCurrentPage(currentPage - 1);
        }
    };


    return (
        <>
            <div className="relative">
                <br />
                <div className="w-full mb-4">
                    <p className="text-gray-700 dark:text-gray-200 font-semibold text-md">
                        Total {tableHeading}: {data?.length}
                    </p>
                </div>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  rounded-lg shadow-md">
                    {/* Table Head */}
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-800 dark:text-gray-400">
                        <tr>
                            {checkboxRow && (
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        <input
                                            id="checkbox-all-search"
                                            type="checkbox"
                                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label htmlFor="checkbox-all-search" className="sr-only">
                                            Select all
                                        </label>
                                    </div>
                                </th>
                            )}
                            {columns &&
                                columns.map((column) => (
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-gray-800 dark:text-gray-200 font-semibold tracking-wide border-b"
                                        key={column.key}
                                    >
                                        {column.label}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody>
                        {
                            data?.length === 0 && currentItems?.length === 0 ?
                                <>
                                    <tr>
                                        <td colSpan="10" className="text-center py-4">
                                            No {tableHeading || "Data"}  Found.
                                        </td>
                                    </tr>
                                </>
                                :
                                <>
                                    {
                                        type === app_consts.WAREHOUSE_RECEIPT_TABLE ?
                                            <WarehouseReceiptHistoryTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                            :
                                            type === app_consts.OFFER_HISTORY_TABLE ?
                                                <OfferHistoryTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                :
                                                type === app_consts.BROKER_OFFER_TRADE_TABLE ?
                                                    <BrokerOfferTradeTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                    :
                                                    type === app_consts.BID_MANAGEMENT_TABLE ?
                                                        <BidManagemenetTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                        :
                                                        type === app_consts.WAREHOUSE_RECEIPT_LIST_TABLE ?
                                                            <WarehouseReceiptListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                            :
                                                            type === app_consts.TRADE_FULFILLMENTS_LIST_TABLE ?
                                                                <TradeFulfillmentListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                :
                                                                type === app_consts.SUPER_BID_MANAGEMENT_TABLE ?
                                                                    <SuperBidManagemenetTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                    :
                                                                    type === app_consts.ENTITY_USER_PROFILES_TABLE ?
                                                                        <EntityProfileUserProfilesTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                        :
                                                                        type === app_consts.PROVISION_RECEIPT_LIST_TABLE ?
                                                                            <ProvisionReceiptListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                            :
                                                                            type === app_consts.WAREHOUSE_REPORTS ?
                                                                                <WarehouseReportsTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                :
                                                                                type === app_consts.WAREHOUSE_LOCATIONS_TABLE ?
                                                                                    <WarehouseLocationsListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                    :
                                                                                    type === app_consts.INBOUND_SHIPMENTS_TABLE ?
                                                                                        <InboundShipmentsTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                        :
                                                                                        type === app_consts.GOODS_RECEIVED_NOTE_TABLE ?
                                                                                            <GRNInfoTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                            :
                                                                                            type === app_consts.WHR_DEPOSITORS ?
                                                                                                <ProducerWarehouseReceiptsTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                :
                                                                                                type === app_consts.SPECIFIC_PRODUCER_WHR_TABLE ?
                                                                                                    <SpecificProducerWarehouseReceiptsTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                    :
                                                                                                    type === app_consts.SPECIFIC_PRODUCER_WHR_GRN_TABLE ?
                                                                                                        <SpecificWarehouseReceiptsDepositorsGRNTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                        :
                                                                                                        type === app_consts.REPORTS_AUDIT_TRAILS_TABLE ?
                                                                                                            <ReportsAuditTrailsTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                            :
                                                                                                            type === app_consts.EXCHANGE_PRODUCERS_LIST ?
                                                                                                                <ExchangeProducerListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                :
                                                                                                                type === app_consts.EXCHANGE_PRODUCER_BROKERS ?
                                                                                                                    <ExchangeProducerBrokersTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                    :
                                                                                                                    type === app_consts.BROKER_PRODUCERS ?
                                                                                                                        <BrokerProducersTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                        :
                                                                                                                        type === app_consts.ATS_MANAGEMENT ?
                                                                                                                            <AtsManagementTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                            :
                                                                                                                            type === app_consts.MEMBERSHIP_MANAGEMENT ?
                                                                                                                                <MemberShipManagement currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                :
                                                                                                                                type === app_consts.ENTITY_PROFILES_LIST_EXCHANGE_VIEW ?
                                                                                                                                    <EntityProfilesExchangeTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                    :
                                                                                                                                    type === app_consts.BANK_ACCOUNTS ?
                                                                                                                                        <BankAccountsTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                        :
                                                                                                                                        type === app_consts.WAREHOUSE_OPERATOR_LIST ?
                                                                                                                                            <ExchangeWarehouseOperatorTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                            :
                                                                                                                                            type === app_consts.USER_PROFILES_TABLE ?
                                                                                                                                                <UserProfilesTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                :
                                                                                                                                                type === app_consts.BROKER_LIST ?
                                                                                                                                                    <ExchangeBrokersTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                    :
                                                                                                                                                    type === app_consts.EXCHANGE_WAREHOUSE_LIST ?
                                                                                                                                                        <ExchangeWarehouseLocationsListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                        :
                                                                                                                                                        type === app_consts.EXCHANGE_BROKER_DETAILS_SUPER_OFFERS ?
                                                                                                                                                            <ExchangeBrokerDetailsSuperOffersTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                            :
                                                                                                                                                            type === app_consts.SPECIFIC_USER_PROFILES_TABLE ?
                                                                                                                                                                <SpecificUserProfilesTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                                :
                                                                                                                                                                type === app_consts.EXCHANGE_BROKER_DETAILS_SUPER_BIDS ?
                                                                                                                                                                    <ExchangeBrokerDetailsSuperBidsTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                                    :
                                                                                                                                                                    type === app_consts.COOPERATIVE_LIST ?
                                                                                                                                                                        <ExchangeCooperativesTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                                        :
                                                                                                                                                                        type === app_consts.EXCHANGE_ENTITY_PRODUCERS_LIST ?
                                                                                                                                                                            <ExchangeEntityProducersTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                                            :
                                                                                                                                                                            type === app_consts.USER_LIST ?
                                                                                                                                                                                <UsersTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                                                :
                                                                                                                                                                                type === app_consts.USER_ACCOUNT_PROFILES ?
                                                                                                                                                                                    <UserAccountProfileTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                                                    :
                                                                                                                                                                                    type === app_consts.USER_ACCOUNT_BROKERS_COOPERATIVES ?
                                                                                                                                                                                        <UserAccountBrokersCooperatives currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                                                                                                                                        :
                                                                                                                                                                                        type === app_consts.INVALID_COOPERATIVE_ENTITY_CSV_DATA ?
                                                                                                                                                                                            <InvalidCooperativeEntityCSVDataTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} rowAction={rowAction} /> :
                                                                                                                                                                                            type === app_consts.VALID_COOPERATIVE_ENTITY_CSV_DATA ?
                                                                                                                                                                                                <ValidCooperativeEntityCSVDataTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} rowAction={rowAction} /> :
                                                                                                                                                                                                type === app_consts.INVALID_COOPERATIVE_MEMBER_CSV_DATA ?
                                                                                                                                                                                                    <InvalidCooperativeMemberCSVDataTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} rowAction={rowAction} /> :
                                                                                                                                                                                                    type === app_consts.VALID_COOPERATIVE_MEMBER_CSV_DATA ?
                                                                                                                                                                                                        <ValidCooperativeMemberCSVDataTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} rowAction={rowAction} /> :
                                                                                                                                                                                                        type === app_consts.BROKER_BID_TRADE_TABLE ?
                                                                                                                                                                                                            <BrokerBidTradeTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} /> :
                                                                                                                                                                                                            type === app_consts.BROKER_SUPER_BIDS_LIST ?
                                                                                                                                                                                                                <BrokerSuperBidListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} /> :
                                                                                                                                                                                                                type === app_consts.BROKER_SUPER_OFFERS_LIST ?
                                                                                                                                                                                                                    <BrokerSuperOfferListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} /> :
                                                                                                                                                                                                                    type === app_consts.BROKER_SUB_BIDS_LIST ?
                                                                                                                                                                                                                        <BrokerSubBidListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} /> :
                                                                                                                                                                                                                        type === app_consts.BROKER_SUB_OFFERS_LIST ?
                                                                                                                                                                                                                            <BrokerSubOffersListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} /> :
                                                                                                                                                                                                                            type === app_consts.EXCHANGE_TRADE_FULFILLMENTS_LIST_TABLE ?
                                                                                                                                                                                                                                <ExchangeTradeFullfilmentListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} /> :
                                                                                                                                                                                                                                type === app_consts.WAREHOUSE_OPERATOR_INVENTORY_DATA ?
                                                                                                                                                                                                                                    <WarehouseOperatorInventoryListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} /> :

                                                                                                                                                                                                                                    ""
                                    }
                                </>
                        }
                    </tbody>
                </table>{
                    data && data?.length !== 0 && (
                        <nav className=" pt-4" aria-label="Table navigation">
                            {/* Pagination controls */}
                            <div className="flex flex-row items-center justify-between mt-4 mr-8">
                                <div className="flex-1">
                                    <p className="text-sm text-gray-800 dark:text-gray-400">
                                        Showing {startIndex + 1}-{Math.min(endIndex, totalDataSet)} of {totalDataSet} {tableHeading}
                                    </p>
                                </div>
                                {
                                    data && totalDataSet > pageSize && (
                                        <>
                                            <div className="flex">
                                                <button
                                                    onClick={prevPage}
                                                    disabled={currentPage === 1}
                                                    className="px-3 py-1 text-sm text-gray-500 dark:text-gray-400 rounded-full border border-grey-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-white cursor-pointer"
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    onClick={nextPage}
                                                    disabled={currentPage * pageSize >= totalDataSet}
                                                    className="ml-2 px-3 py-1 text-sm text-gray-500 dark:text-gray-400 rounded-full border border-grey-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-white cursor-pointer"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </nav>
                    )
                }
            </div>
        </>
    );
};

export default TableComp;