import React, { useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import NavLogo from '../../components/NavLogo';
import WelcomeBanner from '../../layouts/WelcomeBanner';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityProfileList } from '../../utils/actions';
import { HiCog, HiOutlineChartBar, HiOutlineCheckCircle, HiOutlineUsers, HiUser } from 'react-icons/hi';
import { FaExchangeAlt } from 'react-icons/fa';
import { HiOutlineClipboardCheck, HiOutlineClipboardList } from 'react-icons/hi';
import { RiUserSettingsLine } from 'react-icons/ri';
import MainExchangeSideMenu from '../../layouts/side_menus/MainExchangeSideMenu';
import Icon from '../../components/Icon';

const MainDashboard = () => {

    const navigate = useNavigate();
    const account = JSON.parse(localStorage.getItem('account'));
    const dispatch = useDispatch();

    // Fetch Profile Lists Under this Entity
    useEffect(() => {
        const fetchProfileList = async () => {
            try
            {
                dispatch(getEntityProfileList(entity_id));
            } catch (error)
            {
                console.error(error);
            }
        };
        fetchProfileList();
    }, []);

    const [profilesList, setProfilesList] = useState([]);

    // Retrieve Profile List from State
    const profile_list = useSelector((state) => state.entityProfilesList);

    useEffect(() => {
        if (profile_list.data) 
        {
            const selectedlist = profile_list.data?.find((profile) => profile.member_type_id === entity_mid);
            setProfilesList(selectedlist);
        }
    }, [profile_list]);

    const { entity_id, entity_mid } = useParams();

    const profiles = account.profiles;
    const zamaceProfile = profiles.find(profile => profile.type === "exchange");

    // Handle Logout
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    return (
        <div>
            <MainExchangeSideMenu entity_id={entity_id} entity_mid={entity_mid} activeLabel="Exchange Dashboard" />

            <div className="p-4 sm:ml-64">
                <div className="p-4 rounded-lg dark:border-gray-700">

                    <WelcomeBanner bannerTitle={`Exchange Dashboard ${profilesList && profilesList.entity_name ? ` - ${profilesList.entity_name}` : ""}`} data={profilesList} view />

                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">

                        <a href={`/exchange/${entity_id}/${entity_mid}/membership`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                            <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                                <HiOutlineUsers className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                                <span className="text-lg font-semibold text-green-700">Membership Management</span>
                                <span className="text-sm text-gray-500 mt-1 block hidden md:block">Accounts and User Membership Mangement</span>
                            </div>
                        </a>

                        <a href={`/exchange/${entity_id}/${entity_mid}/markets`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                            <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                                <FaExchangeAlt className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                                <span className="text-lg font-semibold text-green-700">Markets</span>
                                <span className="text-sm text-gray-500 mt-1 block hidden md:block">Market Transactions Occuring on Platform</span>
                            </div>
                        </a>

                        <a href="#" class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                            <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                                <HiOutlineClipboardCheck className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                                <span className="text-lg font-semibold text-green-700">Inspections & Certifications</span>
                                <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Inspections & Certifications</span>
                            </div>
                        </a>

                        <a href={`/exchange/${entity_id}/${entity_mid}/receipts_repository`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                            <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                                <HiOutlineClipboardList className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                                <span className="text-lg font-semibold text-green-700">Receipts Repository</span>
                                <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Receipts Repository</span>
                            </div>
                        </a>

                        <a href="#" class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                            <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                                <HiOutlineCheckCircle className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                                <span className="text-lg font-semibold text-green-700">Commodity Standardization</span>
                                <span className="text-sm text-gray-500 mt-1 block hidden md:block">Commodity Standardization & Grading</span>
                            </div>
                        </a>

                        <a href={`/exchange/${entity_id}/${entity_mid}/analytics`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                            <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                                <HiOutlineChartBar className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                                <span className="text-lg font-semibold text-green-700">Data Analytics</span>
                                <span className="text-sm text-gray-500 mt-1 block hidden md:block">System Data Analytics</span>
                            </div>
                        </a>

                        {
                            zamaceProfile?.roles?.includes("administrator") && (
                                <>

                                    <a href={`/exchange/${entity_id}/${entity_mid}/profiles`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                                        <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                                            <RiUserSettingsLine className="w-6 h-6" />
                                        </div>
                                        <div className="ml-4">
                                            <span className="text-lg font-semibold text-green-700">Profiles</span>
                                            <span className="text-sm text-gray-500 mt-1 block hidden md:block">ZAMACE Echange Profiles</span>
                                        </div>
                                    </a>
                                </>
                            )
                        }
                    </div>
                    <div className="flex justify-center mt-4">
                        <Icon opacity={0.5} height="30mm" width="40mm" />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MainDashboard;
