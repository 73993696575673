import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './utils/store';

import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import AccountVerification from './pages/auth/AccountVerification';
import Login from './pages/auth/Login';
import Register from './pages/registration/Register';
import NotFound404 from './pages/NotFound404';
import MembershipType from './pages/registration/MembershipType';
import EntityKycInformation from './pages/registration/EntityKycInformation';
import UploadCertificateProfile from './pages/registration/UploadCertificateProfile';
import UploadTaxCertificateProfile from './pages/registration/UploadTaxCertificateProfile';
import UploadOrganizationProfile from './pages/registration/UploadOrganizationProfile';
import UploadBoardResolutionProfile from './pages/registration/UploadBoardResolutionProfile';
import EntityManagementInformation from './pages/registration/EntityManagementInformation';
import BankAccountDetails from './pages/registration/BankAccountDetails';
import SubmitRegistration from './pages/registration/SubmitRegistration';
import RegistrationComplete from './pages/registration/RegistrationComplete';
import Home from './pages/Home';
import MainDashboard from './pages/exchange/MainDashboard';
import MembershipManagementDashboard from './pages/exchange/MembershipManagementDashboard';
import WarehouseOperatorDashboard from './pages/warehouse/WarehouseOperatorDashboard';
import EntityAccountDetails from './pages/exchange/EntityAccountDetails';
import WarehouseLocations from './pages/warehouse/WarehouseLocations';
import InboundShipments from './pages/warehouse/InboundShipments';
import WarehouseStorage from './pages/warehouse/WarehouseStorage';
import WarehouseReceipts from './pages/warehouse/WarehouseReceipts';
import WarehouseReports from './pages/warehouse/WarehouseReports';
import ProcessWarehouseReceiptIndex from './pages/warehouse/process_warehouse_receipt/ProcessWarehouseReceiptIndex';
import WarehouseOperatorIndex from './pages/warehouse/WarehouseOperatorIndex';
import SpecificWarehouse from './pages/warehouse/SpecificWarehouse';
import ForgotPassword from './pages/auth/ForgotPassword';
import BrokerDashboard from './pages/broker/BrokerDashboard';
import BidManagement from './pages/broker/bids/BidManagement';
import OfferManagement from './pages/broker/offers/OfferManagement';
import CustomerManagement from './pages/broker/customers/CustomerManagement';
import CustomerDetails from './pages/broker/customers/CustomerDetails';
import OfferDetails from './pages/broker/offers/OfferDetails';
import AtsDashboard from './pages/ats/AtsDashboard';
import TradeDetails from './pages/ats/AtsDetails';
import SpecificWarehouseReceipt from './pages/warehouse/SpecificWarehouseReceipt';
import SpecificInboundShipment from './pages/warehouse/SpecificInboundShipment';
import CooperativeDashboard from './pages/cooperative/CooperativeDashboard';
import MemberDetails from './pages/cooperative/members/MemberDetails';
import MemberManagement from './pages/cooperative/members/MemberManagement';
import MarketsDashboard from './pages/exchange/markets/MarketsDashboard';
import MarketOfferManagement from './pages/exchange/markets/offers/MarketOfferManagement';
import MartketOfferDetails from './pages/exchange/markets/offers/MarketOfferDetails';
import BrokerManagement from './pages/exchange/membership/brokers/BrokerManagement';
import MarketBrokerDetails from './pages/exchange/membership/brokers/BrokerDetails';
import ReceiptsRepository from './pages/exchange/warehouse/ReceiptsRepository';
import UserAccountDetails from './pages/exchange/membership/UserAccountDetails';
import UserAccountManagement from './pages/exchange/membership/UserAccountManagement';
import AtsDetail from './pages/ats/AtsDetails';
import OfferTradeDetails from './pages/broker/offers/OfferTradeDetails';
import BidDetails from './pages/broker/bids/BidDetails';
import TradeFulfillments from './pages/warehouse/TradeFulfillments';
import SpecificTradeFulfillment from './pages/warehouse/SpecificTradeFulfillment';
import AtsManagement from './pages/exchange/markets/ats_management/AtsManagement';
import CooperativeManagement from './pages/exchange/membership/cooperatives/CooperativeManagement';
import CooperativeDetails from './pages/exchange/membership/cooperatives/CooperativeDetails';
import AtsBidDetail from './pages/ats/AtsBidDetails';
import MarketBidManagement from './pages/exchange/markets/bids/MarketBidManagement';
import MarketBidDetails from './pages/exchange/markets/bids/MarketBidDetails';
import ProvisionReceipts from './pages/broker/provision_receipt/ProvisionReceipts';
import ProvisionReceiptManagement from './pages/exchange/markets/provision_receipts/ProvisionReceiptManagement';
import ProvisionReceiptDetails from './pages/exchange/markets/provision_receipts/ProvisionReceiptDetails';
import UploadPacraPrintout from './pages/registration/UploadPacraPrintout';
import SuperBidDetails from './pages/broker/bids/SuperBidDetails';
import WarehouseProfile from './pages/warehouse/WarehouseProfile';
import WarehouseProfileUserDetails from './pages/warehouse/WarehouseProfileUserDetails';
import ProcessBulkWarehouseReceiptIndex from './pages/warehouse/process_bulk_warehouse_receipt/ProcessBulkWarehouseReceiptIndex';
import ProvisionalOfferDetails from './pages/broker/offers/ProvisionalOfferDetails';
import SpecificProvisionalReceipt from './pages/broker/provision_receipt/SpecificProvisionalReceipt';
import ProcessGoodsReceivedNoteIndex from './pages/warehouse/goods_received_note/ProcessGoodsReceivedNoteIndex';
import WHRDepositors from './pages/warehouse/WHRDepositors';
import SpecificWHRDepositor from './pages/warehouse/SpecificWHRDepositor';
import ExchangeProducerlist from './pages/exchange/membership/producers/ExchangeProducerlist';
import ExchangeProducerDetails from './pages/exchange/membership/producers/ExchangeProducerDetails';
import WarehouseManagement from './pages/exchange/membership/warehouse/WarehouseManagement';
import BrokerDetails from './pages/exchange/membership/brokers/BrokerDetails';
import AnalyticsDashboard from './pages/exchange/analytics/AnalyticsDashboard';
import CooperativeEntityCSVImort from './pages/exchange/membership/cooperatives/CooperativeEntityCSVImport';
import CooperativeMemberCSVImport from './pages/exchange/membership/cooperatives/CooperativeMemberCSVImport';
import ExchangeProfiles from './pages/exchange/exchange_profiles/ExchangeProfiles';
import SubOfferDetails from './pages/broker/offers/SubOfferDetails';
import BidTradeDetails from './pages/broker/bids/BidTradeDetails';
import ExchangeMarketsReportsDashboard from './pages/exchange/markets/reports/ExchangeMarketsReportsDashboard';

function App () {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className='App'>
          <Routes>
            <Route path='/' element={<PublicRoute element={Login} />} />
            <Route
              path='/register'
              element={<PublicRoute element={Register} />}
            />
            <Route
              path='/reset_password'
              element={<PublicRoute element={ForgotPassword} />}
            />
            <Route path='/home' element={<ProtectedRoute element={Home} />} />
            <Route
              path='/verify_account'
              element={<ProtectedRoute element={AccountVerification} />}
            />
            <Route
              path='/membership_type'
              element={<ProtectedRoute element={MembershipType} />}
            />
            <Route
              path='/complete_entity_kyc'
              element={<ProtectedRoute element={EntityKycInformation} />}
            />
            <Route
              path='/registration_certificate'
              element={<ProtectedRoute element={UploadCertificateProfile} />}
            />

            <Route
              path='/registration_pacra_printout'
              element={<ProtectedRoute element={UploadPacraPrintout} />}
            />

            <Route
              path='/registration_tax_certificate'
              element={<ProtectedRoute element={UploadTaxCertificateProfile} />}
            />
            <Route
              path='/organization_profile'
              element={<ProtectedRoute element={UploadOrganizationProfile} />}
            />
            <Route
              path='/board_resolution_document'
              element={
                <ProtectedRoute element={UploadBoardResolutionProfile} />
              }
            />
            <Route
              path='/director_details'
              element={<ProtectedRoute element={EntityManagementInformation} />}
            />
            <Route
              path='/bank_details'
              element={<ProtectedRoute element={BankAccountDetails} />}
            />
            <Route
              path='/registration_declaration'
              element={<ProtectedRoute element={SubmitRegistration} />}
            />
            <Route
              path='/registration_verification'
              element={<ProtectedRoute element={RegistrationComplete} />}
            />
            {/* Exchange Routes */}
            <Route
              path='/exchange/:entity_id/:entity_mid'
              element={<ProtectedRoute element={MainDashboard} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/membership'
              element={
                <ProtectedRoute element={MembershipManagementDashboard} />
              }
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/membership/:organization_id'
              element={
                <ProtectedRoute element={EntityAccountDetails} />
              }
            />

            {/* Warehouse Management */}
            <Route
              path='/exchange/:entity_id/:entity_mid/warehouse_management'
              element={
                <ProtectedRoute element={WarehouseManagement} />
              }
            />

            {/* Broker Management */}
            <Route
              path='/exchange/:entity_id/:entity_mid/broker_management'
              element={<ProtectedRoute element={BrokerManagement} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/broker_management/:member_type_id'
              element={<ProtectedRoute element={BrokerDetails} />}
            />
            {/* Exchange / Cooperatove Routes */}
            <Route
              path='/exchange/:entity_id/:entity_mid/cooperative_management'
              element={<ProtectedRoute element={CooperativeManagement} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/cooperative_management/:member_type_id'
              element={<ProtectedRoute element={CooperativeDetails} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/cooperative_management/entity_csv_import'
              element={<ProtectedRoute element={CooperativeEntityCSVImort} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/cooperative_management/:member_type_id/member_csv_import'
              element={<ProtectedRoute element={CooperativeMemberCSVImport} />}
            />
            {/* User Accounts Routes */}
            <Route
              path='/exchange/:entity_id/:entity_mid/user_management'
              element={<ProtectedRoute element={UserAccountManagement} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/user_management/:id'
              element={<ProtectedRoute element={UserAccountDetails} />}
            />


            {/* Exchange producers */}
            <Route
              path='/exchange/:entity_id/:entity_mid/producer_management'
              element={<ProtectedRoute element={ExchangeProducerlist} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/producer_management/:producer_id'
              element={<ProtectedRoute element={ExchangeProducerDetails} />}
            />
            {/* Warehouse Operator Routes */}
            <Route
              path='/exchange/warehouse'
              element={<ProtectedRoute element={WarehouseOperatorDashboard} />}
            />

            {/* Data Analytics */}
            <Route
              path='/exchange/:entity_id/:entity_mid/analytics'
              element={<ProtectedRoute element={AnalyticsDashboard} />}
            />


            {/* Receipts Repository exchange/receipts_repository */}
            <Route
              path='/exchange/:entity_id/:entity_mid/receipts_repository'
              element={<ProtectedRoute element={ReceiptsRepository} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/receipts_repository/:view/:receipt_number'
              element={<ProtectedRoute element={SpecificWarehouseReceipt} />}
            />


            {/* Warehouse ProcessWarehouseReceipt */}
            {/* Warehouse Operator Routes For Viewing */}
            <Route
              path='/exchange/warehouse'
              element={<ProtectedRoute element={WarehouseOperatorDashboard} />}
            />

            {/* Exchange Profiles */}
            <Route
              path='/exchange/:entity_id/:entity_mid/profiles'
              element={<ProtectedRoute element={ExchangeProfiles} />}
            />


            <Route
              path='/warehouse/:view/:entity_id/:entity_mid'
              element={<ProtectedRoute element={WarehouseOperatorDashboard} />}
            />
            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/locations'
              element={<ProtectedRoute element={WarehouseLocations} />}
            />
            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/locations/:warehouse_id'
              element={<ProtectedRoute element={SpecificWarehouse} />}
            />

            {/* <Route
              path='/warehouse/:view/:entity_id/:entity_mid/storage'
              element={<ProtectedRoute element={WarehouseStorage} />}
            /> */}

            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/inbound_shipments'
              element={<ProtectedRoute element={InboundShipments} />}
            />
            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/inbound_shipments/:delivery_id'
              element={<ProtectedRoute element={SpecificInboundShipment} />}
            />

            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/receipts'
              element={<ProtectedRoute element={WarehouseReceipts} />}
            />
            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/receipts/:receipt_number'
              element={<ProtectedRoute element={SpecificWarehouseReceipt} />}
            />
            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/trade_fulfillments'
              element={<ProtectedRoute element={TradeFulfillments} />}
            />
            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/trade_fulfillments/:contract_reference_no'
              element={<ProtectedRoute element={SpecificTradeFulfillment} />}
            />

            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/profile'
              element={
                <ProtectedRoute element={WarehouseProfile} />
              }
            />

            <Route path="/warehouse/:view/:entity_id/:entity_mid/reports" element={<ProtectedRoute element={WarehouseReports} />} />
            <Route
              path='/warehouse/:view/:entity_id/:entity_mid/profile/:profile_id'
              element={
                <ProtectedRoute element={WarehouseProfileUserDetails} />
              }
            />
            <Route path="/warehouse/:view/:entity_id/:entity_mid/receipt_depositors" element={<ProtectedRoute element={WHRDepositors} />} />
            <Route path="/warehouse/:view/:entity_id/:entity_mid/receipt_depositors/:producer_id" element={<ProtectedRoute element={SpecificWHRDepositor} />} />
            {/* Warehouse ProcessWarehouseReceipt */}
            {/* <Route path="/warehouse/:entity_id" element={<ProtectedRoute element={WarehouseOperatorIndex} />} /> */}
            <Route
              path='/warehouse/:entity_id/:entity_mid'
              element={<ProtectedRoute element={WarehouseOperatorDashboard} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/trade_fulfillments'
              element={<ProtectedRoute element={TradeFulfillments} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/trade_fulfillments/:contract_reference_no'
              element={<ProtectedRoute element={SpecificTradeFulfillment} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/locations'
              element={<ProtectedRoute element={WarehouseLocations} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/locations/:warehouse_id'
              element={<ProtectedRoute element={SpecificWarehouse} />}
            />
            {/* <Route
              path='/warehouse/:entity_id/:entity_mid/storage'
              element={<ProtectedRoute element={WarehouseStorage} />}
            /> */}
            {/* <Route path="/warehouse/:entity_id/:entity_mid/storage" element={<ProtectedRoute element={WarehouseStorage} />} /> */}
            <Route
              path='/warehouse/:entity_id/:entity_mid/inbound_shipments'
              element={<ProtectedRoute element={InboundShipments} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/inbound_shipments/:delivery_id'
              element={<ProtectedRoute element={SpecificInboundShipment} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/inbound_shipments/:delivery_id/process'
              element={
                <ProtectedRoute element={ProcessWarehouseReceiptIndex} />
              }
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/inbound_shipments/:delivery_id/process_grn'
              element={
                <ProtectedRoute element={ProcessGoodsReceivedNoteIndex} />
              }
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/profile'
              element={
                <ProtectedRoute element={WarehouseProfile} />
              }
            />

            <Route
              path='/warehouse/:entity_id/:entity_mid/profile/:profile_id'
              element={
                <ProtectedRoute element={WarehouseProfileUserDetails} />
              }
            />

            <Route
              path='/warehouse/:entity_id/:entity_mid/receipts/process_bulk'
              element={
                <ProtectedRoute element={ProcessBulkWarehouseReceiptIndex} />
              }
            />

            <Route
              path='/warehouse/:entity_id/:entity_mid/receipts'
              element={<ProtectedRoute element={WarehouseReceipts} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/receipts/:receipt_number'
              element={<ProtectedRoute element={SpecificWarehouseReceipt} />}
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/receipts/process'
              element={
                <ProtectedRoute element={ProcessWarehouseReceiptIndex} />
              }
            />
            <Route
              path='/warehouse/:entity_id/:entity_mid/receipts/process_grn'
              element={
                <ProtectedRoute element={ProcessGoodsReceivedNoteIndex} />
              }
            />
            <Route path="/warehouse/:entity_id/:entity_mid/reports" element={<ProtectedRoute element={WarehouseReports} />} />
            <Route path="/warehouse/:entity_id/:entity_mid/receipt_depositors" element={<ProtectedRoute element={WHRDepositors} />} />
            <Route path="/warehouse/:entity_id/:entity_mid/receipt_depositors/:producer_id" element={<ProtectedRoute element={SpecificWHRDepositor} />} />

            {/* Cooperative Routes */}
            <Route
              path='/cooperative'
              element={<ProtectedRoute element={CooperativeDashboard} />}
            />
            <Route
              path='/cooperative/:entity_id/:entity_mid'
              element={<ProtectedRoute element={CooperativeDashboard} />}
            />
            <Route
              path='/cooperative/:entity_id/:entity_mid/member_management'
              element={<ProtectedRoute element={MemberManagement} />}
            />
            <Route
              path='/cooperative/:entity_id/:entity_mid/member_details/:id'
              element={<ProtectedRoute element={MemberDetails} />}
            />

            {/* Markets/ATS Routes */}
            <Route
              path='/exchange/markets'
              element={<ProtectedRoute element={MarketsDashboard} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/markets/offer_management'
              element={<ProtectedRoute element={MarketOfferManagement} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/markets/bid_management'
              element={<ProtectedRoute element={MarketBidManagement} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/markets/provision_receipt_management'
              element={<ProtectedRoute element={ProvisionReceiptManagement} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/markets/ats_management'
              element={<ProtectedRoute element={AtsManagement} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/markets/reports'
              element={<ProtectedRoute element={ExchangeMarketsReportsDashboard} />}
            />
            <Route
              path='/exchange/:entity_id/:entity_mid/markets'
              element={<ProtectedRoute element={MarketsDashboard} />}
            />
            <Route
              path='/markets/:entity_id/:entity_mid'
              element={<ProtectedRoute element={MarketsDashboard} />}
            />
            <Route
              path='/markets/:entity_id/:entity_mid/offer_management'
              element={<ProtectedRoute element={MarketOfferManagement} />}
            />
            <Route
              path='/markets/offer_details/:id'
              element={<ProtectedRoute element={MartketOfferDetails} />}
            />

            <Route
              path='/markets/:entity_id/:entity_mid/bid_management'
              element={<ProtectedRoute element={MarketBidManagement} />}
            />
            <Route
              path='/markets/bid_details/:id'
              element={<ProtectedRoute element={MarketBidDetails} />}
            />

            <Route
              path='/markets/:entity_id/:entity_mid/ats_management'
              element={<ProtectedRoute element={AtsManagement} />}
            />

            <Route
              path='/markets/:entity_id/:entity_mid/provision_receipt_management'
              element={<ProtectedRoute element={ProvisionReceiptManagement} />}
            />

            <Route
              path='/markets/provision_receipt_details/:id'
              element={<ProtectedRoute element={ProvisionReceiptDetails} />}
            />




            {/* <Route
              path='/markets/ats_offer_details/:id'
              element={<ProtectedRoute element={AtsOfferDetails} />}
            /> */}

            {/* Broker Routes Views */}
            <Route
              path='/broker/:view/:entity_id/:entity_mid'
              element={<ProtectedRoute element={BrokerDashboard} />}
            />

            <Route
              path='/broker/:view/:entity_id/:entity_mid/customer_management'
              element={<ProtectedRoute element={CustomerManagement} />}
            />

            <Route
              path='/broker/:view/:entity_id/:entity_mid/customer_details/:id'
              element={<ProtectedRoute element={CustomerDetails} />}
            />

            <Route
              path='/broker/:view/:entity_id/:entity_mid/offer_management'
              element={<ProtectedRoute element={OfferManagement} />}
            />

            <Route
              path='/broker/:view/:entity_id/:entity_mid/offer_management/:broker_offer_id'
              element={<ProtectedRoute element={OfferDetails} />}
            />

            <Route
              path='/broker/:view/:entity_id/:entity_mid/offer_management/:broker_offer_id/trade/:trade_id'
              element={<ProtectedRoute element={OfferTradeDetails} />}
            />

            {/* Broker Routes */}
            <Route
              path='/broker/:entity_id/:entity_mid'
              element={<ProtectedRoute element={BrokerDashboard} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/customer_management'
              element={<ProtectedRoute element={CustomerManagement} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/customer_details/:id'
              element={<ProtectedRoute element={CustomerDetails} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/bid_management'
              element={<ProtectedRoute element={BidManagement} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/bid_management/:broker_bid_id'
              element={<ProtectedRoute element={BidDetails} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/s_bid_management/:broker_bid_id'
              element={<ProtectedRoute element={SuperBidDetails} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/offer_management'
              element={<ProtectedRoute element={OfferManagement} />}
            />
            <Route
              path='/broker/:entity_id/:entity_mid/offer_management/:broker_offer_id'
              element={<ProtectedRoute element={OfferDetails} />}
            />
            <Route
              path='/broker/:entity_id/:entity_mid/offer_management/:broker_offer_id/trade/:trade_id'
              element={<ProtectedRoute element={OfferTradeDetails} />}
            />
            <Route
              path='/broker/:entity_id/:entity_mid/offer_management/sub_offer/:broker_offer_id'
              element={<ProtectedRoute element={SubOfferDetails} />}
            />
            <Route
              path='/broker/:entity_id/:entity_mid/live_market'
              element={<ProtectedRoute element={AtsDashboard} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/live_market/:id'
              element={<ProtectedRoute element={AtsDetail} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/bid/live_market/:id'
              element={<ProtectedRoute element={AtsBidDetail} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/trade_details'
              element={<ProtectedRoute element={TradeDetails} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/provision_receipts'
              element={<ProtectedRoute element={ProvisionReceipts} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/provisional/offer_management/:broker_offer_id'
              element={<ProtectedRoute element={ProvisionalOfferDetails} />}
            />

            <Route
              path='/broker/:entity_id/:entity_mid/provisional_receipts/:receipt_number'
              element={<ProtectedRoute element={SpecificProvisionalReceipt} />}
            />
            <Route
              path='/broker/:entity_id/:entity_mid/bid_management/:broker_bid_id/trade/:trade_id'
              element={<ProtectedRoute element={BidTradeDetails} />}
            />

            {/* Add the catch-all route */}
            <Route path='/*' element={<NotFound404 />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
