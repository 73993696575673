import * as app_consts from './constants';

// Convert UnderScore to Title Case
export const convertUnderscoreToTitleCase = (inputString) => {
  // Split the input string into words based on underscores
  const words = inputString.split('_');

  // Capitalize the first letter of each word and join them with a space
  const result = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return result;
};

export const formatNumber = (number) => {
  const numberFormat = new Intl.NumberFormat('en-ZM', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return numberFormat.format(number);
};

// Add Three Dots
export const addThreeDots = (str, strLength) => {
  if (str)
  {
    if (str.length > strLength)
    {
      return str.slice(0, strLength) + '...';
    }
  }
  return str;
};

// Function to Capitalize Every First Letter of a String
export const capitalizeSentences = (inputString) => {
  // Split the input string into sentences using period as the delimiter
  var sentences = inputString.split('.');

  // Capitalize the first letter of each sentence
  for (var i = 0; i < sentences.length; i++)
  {
    sentences[i] = sentences[i].trim(); // Remove leading/trailing spaces
    if (sentences[i])
    {
      sentences[i] = sentences[i][0].toUpperCase() + sentences[i].slice(1);
    }
  }

  // Join the sentences back into a string
  var result = sentences.join('. ');

  return result;
};

// Function to Get Entity Profile Prefix
export const retrieveEntityProfileSuffix = (value) => {
  if (value === 'warehouse_operator')
  {
    return app_consts.WAREHOUSE_OPERATOR_SUFFIX;
  } else if (value === 'broker')
  {
    return app_consts.BROKER_SUFFIX;
  } else if (value === 'cooperative')
  {
    return app_consts.COOPERATIVE_SUFFIX;
  } else if (value === 'service_provider')
  {
    return app_consts.SERVICE_PROVIDER_SUFFIX;
  } else if (value === 'investor')
  {
    return app_consts.INVESTOR_SUFFIX;
  } else if (value === 'financial_institution')
  {
    return app_consts.FINANCIAL_INSTITUTION_SUFFIX;
  } else if (value === 'exchange')
  {
    return app_consts.EXCHANGE_SUFFIX;
  }
};

// Convert to Title Case
export const convertToTitleCase = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Remove Number and Replace with ' / '.
export const replace_string_with_forward_slash = (inputString) => {
  const replaced_string = inputString?.split('1')?.join(` / `);
  return replaced_string;
};

// Mapping Data to Go in RSelect fields
export const retreive_rSelectVariables = (mapType, data) => {
  let label;
  let value;
  //   Warehouse Map
  if (mapType === app_consts.WAREHOUSES_MAP)
  {
    label = `${data.warehouse_id} - ${data.name}`;
    value = data.warehouse_id;
  }
  // Producers List Map
  else if (mapType === app_consts.PRODUCERS_LIST_MAP)
  {
    if (data?.type !== app_consts.INDIVIDUAL.toLowerCase()) 
    {
      label = `${data.producer_id} - ${data.entity_name}`;
      value = data.id;
    }
    else
    {
      label = `${data.producer_id} - ${data.contact_name}`;
      value = data.id;
    }
    // Pedning Entity List Map
  } else if (mapType === app_consts.PENDING_ENTITY_LIST_MAP)
  {
    label = `${data.entity_name} - ${data.entity_email_address}`;
    value = data.id;
  } else if (mapType === app_consts.SERVICE_PROVIDER_LIST_MAP)
  {
    label = `${data.entity_mid} - ${data.entity_name}`;
    value = data.id;
  } else if (mapType === app_consts.AVAILABLE_STORAGE_LIST_MAP)
  {
    label = `${convertToTitleCase(replace_string_with_forward_slash(data.storage_type))}.  (${parseFloat(data.available_space || 0).toFixed(2)} MT Available)`;
    value = data.storage_type;
  } else if (mapType === app_consts.WAREHOUSES_MAP_VR_LIST)
  {
    {
      label = `${data.warehouse_id} - ${data.name}`;
      value = data.id;
    }
  } else if (mapType === app_consts.ENTITY_LIST_MAP)
  {
    label = `${data.organization_id} - ${data.entity_name}`;
    value = data.id;
  } else if (mapType === app_consts.ACTIVE_BROKER_LIST_MAP)
  {
    label = `${data.member_type_id} - ${data.entity_name
      }`;
    value = data.member_type_id;
  }

  else if (mapType === app_consts.ENTITY_PRODUCERS_LIST_MAP)
  {
    if (data?.type !== app_consts.INDIVIDUAL.toLowerCase()) 
    {
      label = `${data.producer_id} - ${data.entity_name}`;
      value = data.entity_id;
    }
    else
    {
      label = `${data.producer_id} - ${data.contact_name}`;
      value = data.id;
    }
    // Pedning Entity List Map
  }

  return { label, value };
};

// Mapping Data for RSelect
export const rSelectMapFun = async (label, value) => {
  return {
    label: label,
    value: value,
  };
};

// Make Long Number Easily Readable
export const formatEasilyReadableNumber = (number) => {
  if (number === null || isNaN(number))
  {
    return "N/A";
  }
  return new Intl.NumberFormat().format(number);
};

//REGEX for EMPTY or NULL input
export const EMPTYNULLREGEX = /^\s*$/;
//REGEX for Email
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Regex for Zambian Phone Number
export const ZAMIBAN_PHONE_NUMBER_REGEX = /^(26[0-9])(95|96|97|77|76|75)\d{7}$/;
//REGEX for Zambian NRC
export const NRC_REGEX = /^([0-9]{6})\/([0-9]{2})\/([1-3]{1})$/;