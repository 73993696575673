import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import PDFSVGLogo from '../../components/PDFSVGLogo';
import { format, parseISO } from 'date-fns';
import * as app_consts from '../../utils/constants';

const WarehouseOperatorInventoryPDF = ({ pdf_data }) => {

  const [documentData, setDocumentData] = useState(pdf_data);
  const [inventory_data, setinventoryData] = useState();
  const account = JSON.parse(localStorage.getItem('account'));
  const firstName = account.user.first_name;
  const lastName = account.user.last_name;
  const [total_balace, SetTotalBalance] = useState(0);

  // Register the Lato font
  Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

  useEffect(() => {
    if (pdf_data)
    {
      setDocumentData(pdf_data);
      SetTotalBalance(calculateFinalBalance(pdf_data?.all_data || []));
    }
  }, [pdf_data]);

  useEffect(() => {
    if (documentData?.data?.length > 0)
    {
      setinventoryData(groupedByLocation);
    }
  }, [documentData]);

  const groupedByLocation = documentData?.data?.reduce((acc, item) => {
    const location = item?.warehouse?.name;
    if (!acc[location])
    {
      acc[location] = [];
    }
    acc[location].push(item);
    return acc;
  }, {});

  const calculateFinalBalance = (data) => {
    let balance = 0; // Initialize cumulative balance

    data.forEach((item) => {
      const quantity = parseFloat(item?.quantity || 0);

      if (item?.inventory_type === app_consts.INBOUND)
      {
        balance += quantity; // Add for inbound
      } else if (item?.inventory_type === app_consts.OUTBOUND)
      {
        balance -= quantity; // Subtract for outbound
      }
    });

    // Format the final balance with commas and two decimal places
    return balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };


  let balance = 0; // Initialize balance

  return (
    <>
      {
        inventory_data && (
          <Document title="Warehouse Inventory Statement">
            <Page size="A4" style={{
              flexDirection: "column",
              backgroundColor: "rgba(0,0,0,0)",
              height: "100%",
              fontFamily: "Lato"
            }}>
              <View fixed>
                <View style={{
                  width: "100%",
                  paddingRight: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 5,
                }}>
                  <View style={{
                    display: "flex",
                    flexDirection: "column",
                    ViewTransform: "uppercase",
                    width: "50%",
                    paddingLeft: 20,
                  }}>
                    <View style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", position: 'relative' }}>
                      <PDFSVGLogo height='100px' width='100%' />
                    </View>
                  </View>

                  <View style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    justifyContent: "end",
                    textTransform: "uppercase",
                    width: "50%",
                    marginRight: 10,
                  }}>
                    <View style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginBottom: 5,
                      marginTop: 5
                    }}>
                      <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 14 }}>Warehouse Operator:</Text>
                      <Text style={{ fontWeight: 600, fontSize: 14, color: "#252629", letterSpacing: 1, lineHeight: 1.2 }}>{documentData?.operator_name}</Text>
                    </View>
                    <View style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginBottom: 5,
                      marginTop: 5
                    }}>
                      <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 14 }}>Generated By:</Text>
                      <Text style={{ fontWeight: 600, fontSize: 14, color: "#252629", letterSpacing: 1, lineHeight: 1.2 }}>{`${firstName} ${lastName}`}</Text>
                    </View>
                    <View style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}>
                      <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 14 }}>Date Generated:</Text>
                      <Text style={{ fontWeight: 600, letterSpacing: 1, fontSize: 14, color: "#252629", lineHeight: 1.2 }}>{format(new Date(), 'yyyy-MM-dd  HH:mm:ss')}</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View fixed>
                <View style={{
                  width: "100%",
                  paddingRight: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 25,
                }}>
                  <Text style={{ fontWeight: 600, paddingLeft: 6, fontSize: 25, color: "#000", wordSpacing: "0.05px", lineHeight: 0.2, }}>
                    Warehouse Inventory Statement
                  </Text>
                </View>
              </View>

              <View style={{
                display: "flex",
                flexDirection: "row",
                ViewTransform: "uppercase",
                width: "100%",
                paddingLeft: 20,
                marginTop: 15
              }}>
                <View style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 5,
                  width: "50%"
                }}>
                  <Text style={{ fontWeight: 400, fontSize: 12, color: "#000" }}>Total Tonnage: </Text>
                  <Text style={{ fontWeight: 600, fontSize: 12, marginLeft: "4" }}>{`${(documentData?.all_data?.[documentData?.all_data?.length - 1]?.balance || 0)
                    .toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })} MT`}</Text>
                </View>
                <View style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 5,
                  width: "50%"
                }}>
                  <Text style={{ fontWeight: 400, fontSize: 12, color: "#000" }}>Current Tonnage: </Text>
                  <Text style={{ fontWeight: 600, fontSize: 12, marginLeft: "4" }}>{`${(documentData?.data?.[documentData?.data?.length - 1]?.balance || 0)
                    .toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })} MT`}</Text>
                </View>
              </View>
              <View style={{ paddingRight: "30px", paddingLeft: 30, marginTop: 20 }}>
                <View style={{
                  color: "rgba(0, 0, 0, 0.87)",
                  marginTop: 5,
                  borderRadius: 4,
                  borderStyle: "solid",
                  borderColor: "#e5e9f2",
                }}>
                  {
                    Object.entries(inventory_data).map(([location, items]) => {
                      return (
                        <>
                          <View style={{
                            width: "100%",
                            paddingRight: 5,
                            paddingTop: 5,
                            paddingBottom: 5,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "start",
                            borderTopWidth: 2,
                            borderColor: "#e5e9f2",
                            borderStyle: "solid",
                          }}>
                            <View style={{
                              display: "flex",
                              flexDirection: "row",
                              ViewTransform: "uppercase",
                              width: "100%",
                              paddingLeft: 20,
                              marginTop: 15
                            }}>
                              <View style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "50%"
                              }}>
                                <Text style={{ fontWeight: 400, fontSize: 10, color: "#000" }}>LOCATION: </Text>
                                <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: "2" }}>{location}</Text>
                              </View>
                              <View style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "50%"
                              }}>
                                <Text style={{ fontWeight: 400, fontSize: 10, color: "#000" }}>Total Tonnage: </Text>
                                <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: "2" }}>{`${calculateFinalBalance(items)} MT`}</Text>
                              </View>
                            </View>
                          </View>
                          <View style={{
                            marginTop: 5,
                            marginBottom: 5,
                            width: "100%",
                          }}>
                            <View style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              borderTopWidth: 2,
                              borderStyle: "solid",
                              borderColor: "#D1D1D1",
                            }}>
                              <View style={{
                                width: "14.28%",
                                padding: 3,
                              }}>
                                <Text style={{
                                  marginTop: 5,
                                  color: "#302f2f",
                                  fontSize: 12,
                                  lineHeight: 1.6,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "start",
                                  padding: 3
                                }}>Date</Text>
                              </View>
                              <View style={{
                                width: "14.28%",
                                padding: 3,
                              }}>
                                <Text style={{
                                  marginTop: 5,
                                  color: "#302f2f",
                                  fontSize: 12,
                                  lineHeight: 1.6,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "start",
                                  padding: 3
                                }}>Commodity</Text>
                              </View>
                              <View style={{
                                width: "14.28%",
                                padding: 3,
                              }}>
                                <Text style={{
                                  marginTop: 5,
                                  color: "#302f2f",
                                  fontSize: 12,
                                  lineHeight: 1.6,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "start",
                                  padding: 3
                                }}>Origin</Text>
                              </View>
                              <View style={{
                                width: "14.28%",
                                padding: 3,
                              }}>
                                <Text style={{
                                  marginTop: 5,
                                  color: "#302f2f",
                                  fontSize: 12,
                                  lineHeight: 1.6,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "start",
                                  padding: 3
                                }}>Grade</Text>
                              </View>
                              <View style={{
                                width: "14.28%",
                                padding: 3,
                              }}>
                                <Text style={{
                                  marginTop: 5,
                                  color: "#302f2f",
                                  fontSize: 12,
                                  lineHeight: 1.6,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "start",
                                  padding: 3
                                }}>Type</Text>
                              </View>
                              <View style={{
                                width: "14.28%",
                                padding: 3,
                              }}>
                                <Text style={{
                                  marginTop: 5,
                                  color: "#302f2f",
                                  fontSize: 12,
                                  lineHeight: 1.6,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "start",
                                  padding: 3
                                }}>Quantity</Text>
                              </View>
                              <View style={{
                                width: "14.28%",
                                padding: 3,
                              }}>
                                <Text style={{
                                  marginTop: 5,
                                  color: "#302f2f",
                                  fontSize: 12,
                                  lineHeight: 1.6,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "start",
                                  padding: 3
                                }}>Balance</Text>
                              </View>
                            </View>
                          </View>
                          {
                            items?.map((invent) => {


                              // Parse quantity
                              const quantity = parseFloat(invent?.quantity || 0);

                              // Only update balance if quantity is not zero
                              if (invent?.inventory_type === app_consts.INBOUND)
                              {
                                balance += quantity; // Add for inbound
                              } else if (invent?.inventory_type === app_consts.OUTBOUND)
                              {
                                balance -= quantity; // Subtract for outbound
                              }

                              return (
                                <>
                                  <View style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    borderColor: "#e5e9f2",
                                    borderStyle: "solid",
                                    borderBottomWidth: 1,
                                  }}>
                                    <View style={{
                                      width: "14.28%",
                                      padding: 3,
                                    }}>
                                      <Text style={{
                                        color: "#252b32",
                                        fontSize: 8,
                                        fontWeight: 400,
                                        textAlign: "start",
                                        padding: 3,
                                        flexWrap: "wrap", // Ensures text wraps within the container
                                        whiteSpace: "normal", // Allows text wrapping
                                      }}>
                                        {invent?.quantity_info?.date ? format(parseISO(invent?.quantity_info?.date), "yyyy-MM-dd") : "---"}
                                      </Text>
                                    </View>
                                    <View style={{
                                      width: "14.28%",
                                      padding: 3,
                                    }}>
                                      <Text style={{
                                        color: "#252b32",
                                        fontSize: 8,
                                        fontWeight: 400,
                                        textAlign: "start",
                                        padding: 3,
                                        flexWrap: "wrap", // Ensures text wraps within the container
                                        whiteSpace: "normal", // Allows text wrapping
                                      }}>
                                        {invent?.commodity ? invent?.commodity?.replace(/_/g, ' ')?.toUpperCase() : "---"}
                                      </Text>
                                    </View>
                                    <View style={{
                                      width: "14.28%",
                                      padding: 3,
                                    }}>
                                      <Text style={{
                                        color: "#252b32",
                                        fontSize: 8,
                                        fontWeight: 400,
                                        textAlign: "start",
                                        padding: 3,
                                        flexWrap: "wrap", // Ensures text wraps within the container
                                        whiteSpace: "normal", // Allows text wrapping
                                      }}>
                                        {invent?.warehouse_receipt_origin ? invent?.warehouse_receipt_origin?.replace(/_/g, ' ')?.toUpperCase() : "---"}
                                      </Text>
                                    </View>
                                    <View style={{
                                      width: "14.28%",
                                      padding: 3,
                                    }}>
                                      <Text style={{
                                        color: "#252b32",
                                        fontSize: 8,
                                        fontWeight: 400,
                                        textAlign: "start",
                                        padding: 3,
                                        flexWrap: "wrap", // Ensures text wraps within the container
                                        whiteSpace: "normal", // Allows text wrapping
                                      }}>
                                        {invent?.grade ? invent?.grade?.replace(/_/g, ' ')?.toUpperCase() : "---"}
                                      </Text>
                                    </View>
                                    <View style={{
                                      width: "14.28%",
                                      padding: 3,
                                    }}>
                                      <Text style={{
                                        color: "#252b32",
                                        fontSize: 8,
                                        fontWeight: 400,
                                        textAlign: "start",
                                        padding: 3,
                                        flexWrap: "wrap", // Ensures text wraps within the container
                                        whiteSpace: "normal", // Allows text wrapping
                                      }}>
                                        {invent?.inventory_type ? invent?.inventory_type?.replace(/_/g, ' ')?.toUpperCase() : "---"}
                                      </Text>
                                    </View>
                                    <View style={{
                                      width: "14.28%",
                                      padding: 3,
                                    }}>
                                      <Text style={{
                                        color: "#252b32",
                                        fontSize: 8,
                                        fontWeight: 400,
                                        textAlign: "start",
                                        padding: 3,
                                        flexWrap: "wrap", // Ensures text wraps within the container
                                        whiteSpace: "normal", // Allows text wrapping
                                      }}>
                                        {invent?.quantity ? `${quantity.toFixed(2)}` : "0.00"}
                                      </Text>
                                    </View>
                                    <View style={{
                                      width: "14.28%",
                                      padding: 3,
                                    }}>
                                      <Text style={{
                                        color: "#252b32",
                                        fontSize: 8,
                                        fontWeight: 400,
                                        textAlign: "start",
                                        padding: 3,
                                        flexWrap: "wrap", // Ensures text wraps within the container
                                        whiteSpace: "normal", // Allows text wrapping
                                      }}>
                                        {invent?.quantity ? `${balance.toFixed(2)}` : "0.00"}
                                      </Text>
                                    </View>
                                  </View>
                                </>
                              );
                            })
                          }
                        </>
                      );
                    })
                  }
                </View>
              </View>
            </Page>
          </Document>
        )
      }
    </>
  );
};

export default WarehouseOperatorInventoryPDF;