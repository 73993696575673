import React, { useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import NavLogo from '../../components/NavLogo';
import WelcomeBanner from '../../layouts/WelcomeBanner';
import { useNavigate, useParams } from 'react-router-dom';
import { addWarehouse, getCurrentAccount, getEntityProfileList } from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import * as app_consts from '../../utils/constants';
import AddWarehouseModal from '../../components/modals/AddWarehouseModal';
import Icon from '../../components/Icon';
import { FiMapPin, FiShuffle } from 'react-icons/fi';
import { HiOutlineMap, HiOutlineClipboardCheck } from 'react-icons/hi';
import { FaUsers } from 'react-icons/fa';
import { MdBarChart } from 'react-icons/md';
import { RiUserSettingsLine } from 'react-icons/ri';
import WarehouseOperatorModuleSideMenu from '../../layouts/side_menus/WarehouseOperatorModuleSideMenu';


const WarehouseOperatorDashboard = () => {

  const { entity_id, entity_mid, view } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUrl = window.location.href;

  const [profilesList, setProfilesList] = useState([]);


  const [spectator_role_check, setRoleCheck] = useState(false);
  const account = localStorage.getItem('account') ? JSON.parse(localStorage.getItem('account')) : {};
  const warehouseOperatorProfile = account?.profiles.find(profile => profile.type === app_consts.WAREHOUSE_OPERATOR.toLowerCase() && profile.entity_id === entity_id);

  useEffect(() => {
    if (warehouseOperatorProfile)
    {
      setRoleCheck(warehouseOperatorProfile?.roles?.includes("spectator"));
    }
  }, [warehouseOperatorProfile]);

  // Fetch Profile Lists Under this Entity
  useEffect(() => {
    const fetchProfileList = async () => {
      try
      {
        dispatch(getEntityProfileList(entity_id));
      } catch (error)
      {
        console.error(error);
      }
    };
    fetchProfileList();
    dispatch(getCurrentAccount());
  }, []);

  // Retrieve Profile List from State
  const profile_list = useSelector((state) => state.entityProfilesList);

  useEffect(() => {
    if (profile_list.data) 
    {
      const selectedlist = profile_list.data?.find((profile) => profile.member_type_id === entity_mid);
      setProfilesList(selectedlist);
    }
  }, [profile_list]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const closeModal = () => {
    navigate(`/warehouse/${entity_id}/${entity_mid}/locations`);
  };

  // URL Prefixes
  const warehouseUrlPrefix = () => {
    if (view)
    {
      return 'warehouse/view';
    }
    else
    {
      return 'warehouse';
    }
  };

  return (
    <>
      <div>
        <WarehouseOperatorModuleSideMenu warehouseUrlPrefix={warehouseUrlPrefix} entity_id={entity_id} entity_mid={entity_mid} activeLabel="Warehouse Dashboard" />

        <div className="p-4 sm:ml-64">
          <div className="p-4 rounded-lg bg-white dark:bg-gray-800 dark:border-gray-700 shadow-sm">


            <WelcomeBanner bannerTitle={`Warehouse Operator ${profilesList && profilesList.entity_name ? ` - ${profilesList.entity_name}` : ""}`} view data={profilesList} />

            {
              !spectator_role_check && !view && (
                <>
                  <div className="header flex justify-end items-center">
                    <button
                      type="button"
                      onClick={openModal}
                      className="flex items-center justify-center px-4 py-2 text-sm bg-gray-100 font-medium text-gray-90 border-rouned-md border-gray-200 border-2 rounded-lg bg-sate-100 hover:border-primary-700 hover:text-green-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 mb-6"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                      </svg>
                      Add Warehouse
                    </button>
                  </div>
                </>
              )
            }

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
              <a href={`${currentUrl}/locations`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FiMapPin className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Warehouse Locations</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Loaction Management</span>
                </div>
              </a>

              <a href={`${currentUrl}/inbound_shipments`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FiShuffle className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Inbound Shipments</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Inbound Produce</span>
                </div>
              </a>

              <a href={`${currentUrl}/receipts`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <HiOutlineMap className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Warehouse Receipts</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Receipts Management</span>
                </div>
              </a>

              <a href={`${currentUrl}/receipt_depositors`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FaUsers className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">WHR Depositors</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Receipts Depositors</span>
                </div>
              </a>

              <a href={`${currentUrl}/trade_fulfillments`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <HiOutlineClipboardCheck className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Trade Fulfillments</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Confirmed Warehouse Receipt Trades from ATS</span>
                </div>
              </a>

              <a href={`${currentUrl}/reports`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <MdBarChart className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Warehouse Reports</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Reports</span>
                </div>
              </a>

              <a href={`${currentUrl}/profile`} class="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105">

                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <RiUserSettingsLine className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Warehouse Profile</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Warehouse Operator Profile Details</span>
                </div>
              </a>
            </div>

            <div className="flex justify-center mt-4">
              <Icon opacity={0.5} height="30mm" width="40mm" />
            </div>
          </div>
        </div>
      </div>
      <AddWarehouseModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} successFunction={closeModal} />
    </>
  );
};

export default WarehouseOperatorDashboard;
