import React, { useEffect, useState } from 'react';
import Logo from '../../../components/Logo';
import NavLogo from '../../../components/NavLogo';
import { getBrokerOffers, getEntityProfileList, retreiveBrokerOffersByMemberTypeID, retrieveBrokerOffersByMemberTypeIDReset } from '../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import HeaderBanner from '../../../layouts/HeaderBanner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as app_consts from '../../../utils/constants';
import BrokerOfferModal from '../../../components/modals/BrokerOfferModal';
import BrokerOfferProvisionReceiptModal from '../../../components/modals/BrokerOfferProvisionReceiptModal';
import { addThreeDots } from '../../../utils/functionalUtils';
import CustomTabs from '../../../layouts/CustomTabs';
import BrokerModuleSideMenu from '../../../layouts/side_menus/BrokerModuleSideMenu';
import Icon from '../../../components/Icon';
import TableComp from '../../../components/table/TableComp';
import { format, parseISO } from 'date-fns';

const OfferManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profilesList, setProfilesList] = useState([]);
  const [all_offers, setAllOffers] = useState([]);
  const [all_super_offers, setAllSuperOffers] = useState([]);
  const [all_sub_offers, setAllSubOffers] = useState([]);
  const [current_super_offers, setCurrentSuperOffers] = useState([]);
  const [current_sub_offers, setCurrentSubOffers] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const [activeTab, setActiveTab] = useState('Main Offers');
  const [isDataFetched, setIsDataFetched] = useState(false);

  const tabs = [
    { title: 'Main Offers', disabled: !isDataFetched },
    { title: 'Offers on Bids', disabled: !isDataFetched }
  ];

  const brokerOffers = useSelector((state) => state.brokerOfferList.data);
  const fetchedTotalBrokerOffers = useSelector(
    (state) => state.brokerOfferList.total
  );
  const [totalBrokerOffers, setTotalBrokerOffers] = useState(
    fetchedTotalBrokerOffers
  );

  const { entity_id, entity_mid, view } = useParams();

  useEffect(() => {
    dispatch(retreiveBrokerOffersByMemberTypeID(entity_mid));
    fetchProfileList();
  }, []);

  // Fetch Profile List
  const fetchProfileList = async () => {
    try
    {
      dispatch(getEntityProfileList(entity_id));
    } catch (error)
    {
      console.error(error);
    }
  };

  // Retrieve Profile List from State
  const profile_list = useSelector((state) => state.entityProfilesList);
  // 
  useEffect(() => {
    if (profile_list.data)
    {
      const selectedlist = profile_list.data?.find(
        (profile) => profile.member_type_id === entity_mid
      );
      setProfilesList(selectedlist);
    }
  }, [profile_list]);

  // Listen to Broker Bids
  const all_offers_list = useSelector((state) => state.retrieveBrokerOffersByMemberTypeID);

  //
  useEffect(() => {
    if (all_offers_list.status !== app_consts.IDLE)
    {
      if (all_offers_list.isLoading)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        setIsDataFetched(true);
        if (all_offers_list.error)
        {
          setError(all_offers_list.error);
        } else
        {
          setAllOffers(all_offers_list?.data);
          filter_all_sub_offers(all_offers_list?.data);
          filter_all_super_offers(all_offers_list?.data);
        }
        dispatch(retrieveBrokerOffersByMemberTypeIDReset());
      }
    }
  }, [all_offers_list]);

  const filter_all_sub_offers = (list) => {
    const filtered_offers = list?.filter((bid) => bid?.offer_type === "sub_offer");
    setAllSubOffers(filtered_offers);
    setCurrentSubOffers(filtered_offers);
  };

  const filter_all_super_offers = (list) => {
    const filtered_offers = list?.filter(bid => bid?.offer_type === "super_offer");
    setAllSuperOffers(filtered_offers);
    setCurrentSuperOffers(filtered_offers);
  };

  const [openDropDown, setOpenDropdown] = useState(false);

  const [modal, setModal] = useState({
    brokerOfferModal: false,
    individual: false,
    organization: false,
  });

  const closeBrokerOfferModal = () => {
    setModal({ ...modal, brokerOfferModal: false });
  };

  const openSelectedModal = (modalToBeOpened) => {
    setModal({ ...modal, [modalToBeOpened]: true });
  };

  const [provisionModal, setProvisionModal] = useState({
    brokerprovisionModal: false,
    individual: false,
    organization: false,
  });

  const closeBrokerProvisionModal = () => {
    setProvisionModal({ ...provisionModal, brokerprovisionModal: false });
  };

  const openSelectedProvisionModal = (provisionModalToBeOpened) => {
    setProvisionModal({ ...provisionModal, [provisionModalToBeOpened]: true });
  };

  // URL Prefixes
  const brokerUrlPrefix = () => {
    if (view)
    {
      return 'broker/view';
    }
    else
    {
      return 'broker';
    }
  };

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const filteredOffers = () => {
    let displayedOffers;

    if (activeTab === "Main Offers")
    {
      displayedOffers = all_super_offers?.filter((offer) => {
        let combinedParams = [
          offer?.order_type?.replace(/_/g, ' '),
          offer?.commodity?.replace(/_/g, ' '),
          offer?.quality?.replace(/_/g, ' '),
          offer?.location.replace(/_/g, ' '),
          offer?.requested_unit_price && parseFloat(offer?.requested_unit_price)?.toFixed(2),
          offer?.volume_on_offer && parseFloat(offer?.volume_on_offer)?.toFixed(2),
          offer?.offer_status?.replace(/_/g, ' '),
          offer?.commodity_order?.commodity_order_reference_no || "N/A",
          offer?.commodity_order?.inserted_at && format(parseISO(offer?.commodity_order?.inserted_at), "yyyy-MM-dd HH:mm:ss"),
          offer?.commodity_order?.commodity_order_status?.replace(/_/g, " "),
          offer?.total_bids,
        ]
          .join(' ')
          .toLowerCase();

        return combinedParams.includes(searchTerm?.toLowerCase());
      }).slice(startIndex, endIndex);

      setCurrentSuperOffers(displayedOffers || []);
    }
    else if (activeTab === "Offers on Bids")
    {
      displayedOffers = all_sub_offers?.filter((offer) => {
        let combinedParams = [
          offer?.broker_offer_reference_no?.replace(/_/g, ' '),
          offer?.order_type?.replace(/_/g, ' '),
          offer?.commodity?.replace(/_/g, ' '),
          offer?.quality?.replace(/_/g, ' '),
          offer?.location?.replace(/_/g, ' '),
          offer?.offer && parseFloat(offer?.offer)?.toFixed(2),
          offer?.offer_volume && parseFloat(offer?.offer_volume)?.toFixed(2),
          offer?.broker_offer_date && format(parseISO(offer?.broker_offer_date), "yyyy-MM-dd HH:mm:ss"),
          offer?.offer_status?.replace(/_/g, ' '),
          offer?.commodity_order?.commodity_order_reference_no || "N/A",
          offer?.commodity_order?.inserted_at && format(parseISO(offer?.commodity_order?.inserted_at), "yyyy-MM-dd HH:mm:ss"),
          offer?.requested_unit_price && parseFloat(offer?.requested_unit_price)?.toFixed(2),
          offer?.commodity_order?.commodity_order_status,
        ]
          .join(' ')
          .toLowerCase();

        return combinedParams.includes(searchTerm?.toLowerCase());
      }).slice(startIndex, endIndex);

      setCurrentSubOffers(displayedOffers || []);
    }

  };

  // UseEffect for Search 
  useEffect(() => {
    if (searchTerm) 
    {
      filteredOffers();
    }
    else
    {
      filteredOffers();
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm("");
    setCurrentSuperOffers(all_super_offers);
    setCurrentSubOffers(all_sub_offers);
  }, [activeTab]);

  return (
    <div>
      <BrokerModuleSideMenu brokerUrlPrefix={brokerUrlPrefix} entity_id={entity_id} entity_mid={entity_mid} activeLabel="Offers" />
      <div className='p-4 sm:ml-64'>
        <div className='p-4 rounded-lg dark:border-gray-700'>
          <HeaderBanner
            title='Offer Management'
            type={app_consts.OFFER_MANAGEMENT}
            home={`home`}
            header_addition={profilesList && profilesList.entity_name ? profilesList.entity_name : ""}
            navInfo={{ entity_id, entity_mid }}
          />
          <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

          {
            loading ?
              <>
                <div className="text-center my-2">
                  <div className="text-center my-6">
                    <span colSpan="8" className="text-gray-700 py-4 my-2">
                      Loading Offers
                    </span>
                  </div>
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Please wait...</span>
                  </div>
                </div>
              </>
              :
              <>
                {
                  error ?
                    <>
                      <div className='text-center mt-8 h-10'>
                        <div
                          role='status'
                          className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-8 h-8'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                            />
                          </svg>
                        </div>
                        <span
                          colSpan='8'
                          className='text-gray-700 py-4 my-6 tracking-wider'
                        >
                          {error}
                        </span>
                      </div>
                    </>
                    :
                    <>
                      <div class='w-full relative bg-white dark:bg-gray-800 sm:rounded-lg'>
                        <div class='flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4'>
                          <div class='w-full md:w-1/2'>
                            <form class='flex items-center'>
                              <label for='simple-search' class='sr-only'>
                                Search
                              </label>
                              <div class='relative w-full'>
                                <div class='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                  <svg
                                    aria-hidden='true'
                                    class='w-5 h-5 text-gray-500 dark:text-gray-400'
                                    fill='currentColor'
                                    viewbox='0 0 20 20'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      fill-rule='evenodd'
                                      d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                      clip-rule='evenodd'
                                    />
                                  </svg>
                                </div>
                                <input
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  type='text'
                                  id='simple-search'
                                  class='block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                  placeholder='Search'
                                  required=''
                                />
                              </div>
                            </form>
                          </div>
                          <div class='flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3'>
                            <div className='relative flex flex-column'>
                              {
                                !view && (
                                  <button
                                    type='button'
                                    onClick={() => setOpenDropdown(!openDropDown)}
                                    className='flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      viewBox='0 0 20 20'
                                      fill='currentColor'
                                      className='w-5 h-5 mr-2'
                                    >
                                      <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                                      <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                                    </svg>
                                    Create New Offer
                                  </button>
                                )
                              }

                              <div className={`z-10 ${openDropDown ? "absolute origin-top-right mt-12 right-6" : "hidden"} bg-gray-100 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 border-1 border-gray-300`}>
                                <ul className=" border-1 font-bold border-gray-200 rounded-lg text-sm text-gray-800 dark:text-gray-200" aria-labelledby="multiLevelDropdownButton">
                                  <li>
                                    <span onClick={() => setModal({ brokerOfferModal: true })} className="hover:bg-gray-300 cursor-pointer block px-4 py-4 dark:hover:bg-gray-600 dark:hover:text-white">WH Receipt</span>
                                  </li>
                                  <li>
                                    <span onClick={() => setProvisionModal({ brokerprovisionModal: true })} className="hover:bg-gray-300 cursor-pointer block px-4 py-4 dark:hover:bg-gray-600 dark:hover:text-white">Provisional WH Receipt</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {activeTab === 'Main Offers' &&
                        <>
                          <TableComp
                            tableData={current_super_offers}
                            tableType={app_consts.BROKER_SUPER_OFFERS_LIST}
                            tableHeading="Main Offers"
                          />
                        </>
                      }
                      {activeTab === 'Offers on Bids' &&
                        <>
                          <TableComp
                            tableData={current_sub_offers}
                            tableType={app_consts.BROKER_SUB_OFFERS_LIST}
                            tableHeading="Bids on Offer"
                          />
                        </>
                      }
                    </>
                }
              </>
          }

          <div className="flex justify-center mt-4">
            <Icon opacity={0.5} height="30mm" width="40mm" />
          </div>
        </div>
      </div>

      <BrokerOfferModal
        showModal={modal.brokerOfferModal}
        closeModal={closeBrokerOfferModal}
        openSelectedModal={openSelectedModal}
      // fetchBrokerOffers={fetchBrokerOffers}
      />

      <BrokerOfferProvisionReceiptModal
        showModal={provisionModal.brokerprovisionModal}
        closeModal={closeBrokerProvisionModal}
        openSelectedModal={openSelectedProvisionModal}
      // fetchBrokerOffers={fetchBrokerOffers}
      />
    </div>
  );
};

export default OfferManagement;