import React, { useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import NavLogo from '../../components/NavLogo';
import WelcomeBanner from '../../layouts/WelcomeBanner';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityProfileList } from '../../utils/actions';
import Icon from '../../components/Icon';
import BrokerModuleSideMenu from '../../layouts/side_menus/BrokerModuleSideMenu';
import {
  FaBars,
  FaChartLine,
  FaUserFriends,
  FaGavel,
  FaHandHoldingUsd,
  FaMoneyCheckAlt,
  FaSignOutAlt,
  FaClock,
} from 'react-icons/fa';

const BrokerDashboard = () => {
  const { entity_id, entity_mid, view } = useParams();
  const currentUrl = window.location.href;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const account = JSON.parse(localStorage.getItem('account'));
  const firstName = account.user.first_name;
  const lastName = account.user.last_name;
  const [profilesList, setProfilesList] = useState([]);

  // Retrieve Profile List from State
  const profile_list = useSelector((state) => state.entityProfilesList);

  useEffect(() => {
    if (profile_list.data) 
    {
      const selectedlist = profile_list.data?.find((profile) => profile.member_type_id === entity_mid);
      setProfilesList(selectedlist);
    }
  }, [profile_list]);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  useEffect(() => {
    dispatch(getEntityProfileList(entity_id));
  }, []);

  // URL Prefixes
  const brokerUrlPrefix = () => {
    if (view)
    {
      return 'broker/view';
    }
    else
    {
      return 'broker';
    }
  };

  return (
    <>
      <div>
        <BrokerModuleSideMenu brokerUrlPrefix={brokerUrlPrefix} entity_id={entity_id} entity_mid={entity_mid} activeLabel="Broker Dashboard" />

        <div className="p-4 sm:ml-64">
          <div className="p-4 rounded-lg bg-white dark:bg-gray-800 dark:border-gray-700 shadow-sm">
            <WelcomeBanner bannerTitle={`Broker Dashboard ${profilesList?.entity_name ? ` - ${profilesList.entity_name}` : ""}`} view data={profilesList} />

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
              {/* Client Management Card */}
              <a
                href={`${currentUrl}/customer_management`}
                className="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105"
              >
                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FaUserFriends className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Clients</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Client Registration and Management</span>
                </div>
              </a>

              {/* Bids Management Card */}
              <a
                href={`${currentUrl}/bid_management`}
                className="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105"
              >
                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FaGavel className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Bids</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Creation and Management of Bids</span>
                </div>
              </a>

              {/* Offers Management Card */}
              <a
                href={`${currentUrl}/offer_management`}
                className="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105"
              >
                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FaHandHoldingUsd className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Offers</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Creation and Management of Offers</span>
                </div>
              </a>

              {/* Live Market Card */}
              <a
                href={`${currentUrl}/live_market`}
                className="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105"
              >
                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FaMoneyCheckAlt className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Live Market</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Time Live Market Data</span>
                </div>
              </a>

              <a
                href={`${currentUrl}/provision_receipts`}
                className="flex items-center hover:shadow-xl select-none p-6 rounded-lg border border-gray-300 hover:border-green-500 cursor-pointer transition ease-in-out duration-300 transform hover:scale-105"
              >
                <div className="w-12 h-12 bg-green-100 rounded-full flex justify-center items-center">
                  <FaClock className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-green-700">Provisional Receipts</span>
                  <span className="text-sm text-gray-500 mt-1 block hidden md:block">Creation and Management of Provisional Receipts</span>
                </div>
              </a>
            </div>

            <div className="flex justify-center mt-4">
              <Icon opacity={0.5} height="30mm" width="40mm" />
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default BrokerDashboard;
